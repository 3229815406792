<template>
  <div @click.stop="transitionToCard(item)" class="trash-card">
    <img class="trash-card-image" v-if="item.info && item.info.image" :src="item.info.image" :alt="item.info.name">
    <div class="trash-card-info-wrapper">
      <p class="trash-card-name" v-if="item.info && item.info.name">{{ item.params.platform.toUpperCase() }} | {{ item.info.name }}</p>
      <div class="trash-card-price-wrapper">
        <p
            v-if="item.info && item.info.price"
            :style="item.params && item.params.sale > 0 ? 'text-decoration: line-through' : ''"
            class="trash-card-price">
          {{ item.info.price[switcher.country].toLocaleString() }}
          {{ item.params && item.params.sale === 0 ? switcher.country === 'kg' ? 'сом' : 'руб' : '' }}</p>
        <p
            v-if="item.params && item.params.sale > 0"
            class="trash-card-price trash-card-price-sale">
          {{ calculateDiscountedPrice(item.info.price[switcher.country], item.params?.sale).toLocaleString() }}
          {{ switcher.country === 'kg' ? 'сом' : 'руб' }}</p>
      </div>
    </div>
    <button v-if="btnDelete" @click.stop="store.inTrash(item.id)" class="trash-card-delete">
      <svg class="trash-card-delete-icon" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <path d="M9.63599 22.364L22.3639 9.63603" stroke="#e23881" stroke-width="2" stroke-linecap="round"></path>
        <path d="M9.63599 9.63602L22.3639 22.3639" stroke="#e23881" stroke-width="2" stroke-linecap="round"></path></svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { useSwitcherStore } from "@/store/switcher"
import { calculateDiscountedPrice } from '@/utils/helpers'
import { useCatalogStore } from "@/store/catalog";
import { useRouter } from "vue-router";
const store = useCatalogStore()
const router = useRouter()
const switcher = useSwitcherStore()

const emits = defineEmits('onClickCard')

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  btnDelete: {
    type: Boolean,
    default: true
  },
  changeSideBarTrash: {
    type: Boolean,
    default: true
  }
})

const transitionToCard = (item:any) => {
  const type = item?.params?.type === 'игра' ? 'games' : 'product'
  router.push(`/catalog/${item?.params?.platform}/${type}/${item?.id}`)
  if(props?.changeSideBarTrash) switcher.changeSideBarTrash()
  emits('onClickCard')
}

</script>

<style lang="scss" scoped>
.trash-card {
  position: relative;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  gap: 10px;
  &:hover {
    transition: all 0.1s ease-in;
    background-color: #e5e5e5;
  }
}
.trash-card:first-child {
  border-top:  1px solid rgba(0, 0, 0, 0.2);
}
.trash-card:last-child {
  margin-bottom: 0;
}
.trash-card-image {
  width: 43px;
  height: 47px;
  margin-left: 10px;
}
.trash-card-delete {
  position: absolute;
  right: 10px;
  top: 7px;
}
.trash-card-delete-icon {
  width: 20px;
  height: 20px;
}
.trash-card-name {
  font-size: 14px;
  font-weight: 500;
  max-width: 180px;
}
.trash-card-info-wrapper {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  gap: 10px;
}
.trash-card-price-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}
.trash-card-price {
  font-size: 14px;
  font-weight: 500;
  color: #242424;
}
.trash-card-price-sale {
  color: #e23881;
}

@media screen and (min-width: 1440px) {
  .trash-card-name, .trash-card-price {
    font-size: 16px;
  }
  .trash-card-image {
    width: 51px;
    height: 71px;
  }
}
</style>
