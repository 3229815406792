<template>
  <section class="last-buy">
    <div class="container">
      <h2 class="last-buy-title">Последние покупки</h2>
      <swiper
          :breakpoints="breakpoints"
          :space-between="10"
          :navigation="false"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: true,
          }"
          :modules="modules"
      >
        <swiper-slide
            v-for="(buy, index) in lastBuy"
            :key="index"
        >
          <div class="last-buy-card">
            <div class="last-buy-card-top">
              <img class="last-buy-card-top-image" :src="buy.avatar" alt="Аватарка">
              <div class="last-buy-card-top-info">
                <p class="last-buy-card-top-name">{{ buy.date }}</p>
                <div class="last-buy-card-top-info-layout">
                  <p class="last-buy-card-top-name">{{ buy.delivery }}</p> |
                  <p class="last-buy-card-top-name">{{ buy.game }}</p>
                </div>
              </div>
            </div>
            <p class="last-buy-card-top-text">
              {{ buy.description }}
            </p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useCatalogStore } from "@/store/catalog";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
const useCatalog = useCatalogStore()
const lastBuy = useCatalog.lastBuyItems
import { Autoplay } from 'swiper/modules';
import { reactive } from "vue";

const breakpoints = {
  360: {
    slidesPerView: 1
  },
  576: {
    slidesPerView: 2,
    spaceBetween: 15
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 20
  }
}

const modules = reactive([Autoplay])
</script>

<style lang="scss" scoped>
.last-buy {
  margin: 20px 0 40px 0;
}
.last-buy-title {
  font-size: 24px;
  font-weight: 600;
  color: #242424;
  margin: 0;
  padding: 0;
}
.last-buy-card {
  margin-top: 20px;
}
.last-buy-card-top {
  display: flex;
  align-items: center;
  gap: 15px
}
.last-buy-card-top-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}
.last-buy-card-top-info-layout {
  display: flex;
  gap: 10px;
}
.last-buy-card-top-image {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.last-buy-card-top-stars {
  height: 25px;
  width: 94px;
}
.last-buy-card-top-name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
.last-buy-card-top-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.last-buy-card-top-text {
  margin-top: 15px;
}

@media screen and (min-width: 768px) {
  .last-buy-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .last-buy-card-top-name {
    font-size: 18px;
  }
  .last-buy-card-top-text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
}
</style>
