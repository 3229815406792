<template>
  <div class="not-found">
    <h1 class="not-found-title">404</h1>
    <p class="not-found-subtitle">Упс! Страница, которую вы ищете, не существует.</p>
    <router-link to="/" class="not-found-link">Вернуться на главную</router-link>
  </div>
</template>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.not-found-title {
  font-size: 28px;
  color: #242424;
  margin-bottom: 10px;
  font-weight: 600;
}
.not-found-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.not-found-link {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #e23881;
}
</style>
