<template>
 <section class="header">
   <div class="container">
     <div class="header-wrapper">
       <!-- Логотип -->
       <LogoCompany />
      <!--  ПОИСКОВАЯ СТРОКА     -->
       <div class="header-search-desktop">
         <SearchInput />
       </div>
      <!-- Доставка -->
       <div class="header-deliver">
         <img class="header-deliver-image" src="../../../src/assets/icons/header/deliver.svg" alt="Доставка">
         <div class="header-deliver-text">
           <p class="header-deliver-text-name">Доставка</p>
           <p class="header-deliver-text-city">Кыргызстан | Россия</p>
         </div>
       </div>
       <!-- Контакты -->
       <div class="header-contact">
         <img class="header-contact-image" src="../../../src/assets/icons/header/contact.svg" alt="Контакты">
         <div class="header-contact-wrapper">
           <p class="header-contact-wrapper-title">Контакты</p>
           <a  href="tel:+996220467448" target="_blank" class="header-contact-wrapper-link">+996 220 467 448</a>
         </div>
       </div>
     </div>
   </div>
   <div class="header-menu-wrapper">
      <div class="container">
       <div class="header-menu-layout">
         <div class="header-menu-links">
           <button class="header-menu-links-item" @click="scrollToCatalog('catalog-title')">Каталог</button>
           <a class="header-menu-links-item" href="/check-availability/">Уточнить наличие</a>
           <a class="header-menu-links-item" @click="scrollToCatalog('last-buy')">Отзывы</a>
           <a class="header-menu-links-item" href="/delivery/">Доставка и оплата</a>
           <a class="header-menu-links-item" href="/contacts/">Контакты</a>
           <button class="header-menu-links-item" @click="scrollToCatalog('accardeon-title')">Игровой FAQ</button>
         </div>
         <button @click.stop="() => {
           switcher.changeSideBarTrash();
  tgBotAction( 'Кнопка: корзина')
         }" class="header-menu-cart-wrapper">
           <span class="header-menu-cart-count">{{ catalog.length }}</span>
           <img class="header-menu-cart" src="../../../src/assets/icons/shop-cart-white.png" alt="Корзинка">
         </button>
       </div>
      </div>
   </div>
 </section>
</template>

<script lang="ts" setup>
import LogoCompany from '@/components/universal/LogoC.vue'
import SearchInput from '@/components/universal/SearchInput/index.vue'
import { useCatalogStore } from '@/store/catalog'
import { useSwitcherStore } from '@/store/switcher'
import { scrollToElement } from '@/utils/helpers'
import { useRouter } from "vue-router";
import { computed } from 'vue'
import { tgBotAction } from '@/api/telegram/'
const router = useRouter()

const store = useCatalogStore()
const switcher = useSwitcherStore()
const catalog = computed(() => store.itemsTrash)

const scrollToCatalog = (className: string) => {
  router.push('/').then(() => {
    setTimeout(() => {
      scrollToElement({ type: 'class', element: className, offset: 100 })
    }, 50)
  })
}

</script>

<style scoped>

.header {
  padding: 8px 0;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.header-search-desktop {
  display: none;
}
.header-deliver-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.header-contact-image {
  width: 40px;
  height: 40px;
}
.header-deliver, .header-contact {
  display: flex;
  gap: 10px;
}
.header-deliver-text {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}
.header-deliver-text-name {
  font-weight: 500;
  font-size: 14px;
  color: #242424;
}
.header-deliver-text-city {
  font-weight: 500;
  font-size: 14px;
  color: #88b1ff;
}
.header-contact-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.header-contact-wrapper-link {
  font-weight: 500;
  font-size: 14px;
  color: #88b1ff;
  transition: all 0.1s ease-in;
  text-decoration: none;
}
.header-menu-wrapper {
  background: rgba(230, 239, 253, 1);
  padding: 13px 0;
}
.header-menu-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-menu-links {
  display: flex;
  gap: 30px;
}
.header-menu-links-item {
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: #242424;
  cursor: pointer;
}
.header-menu-cart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ef3284;
  position: relative;
  cursor: pointer;
}
.header-menu-cart-count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  right: -5px;
  top: -7px;
}
.header-menu-cart {
  width: 28px;
  height: 28px;
}
.header-contact-wrapper-title {
  font-weight: 500;
  font-size: 14px;
}
.header-menu-wrapper, .header-deliver, .header-contact {
  display: none;
}

@media screen and (min-width: 576px) {
  .header-search-desktop {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .header {
    padding: 15px 0 0 0;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  .header-deliver-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .header-contact-image {
    width: 35px;
    height: 35px;
  }
  .header-deliver, .header-contact {
    display: flex;
    gap: 10px;
  }
  .header-deliver-text {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }
  .header-deliver-text-name {
    font-weight: 500;
    font-size: 14px;
    color: #242424;
  }
  .header-deliver-text-city {
    font-weight: 500;
    font-size: 14px;
    color: #88b1ff;
  }
  .header-contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .header-contact-wrapper-link {
    font-weight: 500;
    font-size: 14px;
    color: #88b1ff;
    transition: all 0.1s ease-in;
    text-decoration: none;
  }
  .header-menu-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-menu-links {
    display: flex;
    gap: 30px;
  }
  .header-menu-links-item {
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: #242424
  }
  .header-menu-cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #ef3284;
    position: relative;
    cursor: pointer;
  }
  .header-menu-cart-count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    right: -5px;
    top: -7px;
  }
  .header-contact-wrapper-title {
    font-weight: 500;
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    padding: 15px 0 0 0;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .header-menu-wrapper {
    display: flex;
  }
  .header-deliver-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .header-contact-image {
    width: 40px;
    height: 40px;
  }
  .header-deliver, .header-contact {
    display: flex;
    gap: 10px;
  }
  .header-deliver-text {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
  .header-deliver-text-name {
    font-weight: 500;
    font-size: 14px;
    color: #242424;
  }
  .header-deliver-text-city {
    font-weight: 500;
    font-size: 14px;
    color: #88b1ff;
  }
  .header-contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .header-contact-wrapper-link {
    font-weight: 500;
    font-size: 14px;
    color: #88b1ff;
    transition: all 0.1s ease-in;
    text-decoration: none;
  }
  .header-menu-wrapper {
    background: rgba(230, 239, 253, 1);
    padding: 13px 0;
  }
  .header-menu-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-menu-links {
    display: flex;
    gap: 30px;
  }
  .header-menu-links-item {
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: #242424
  }
  .header-menu-cart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #ef3284;
    position: relative;
    cursor: pointer;
  }
  .header-menu-cart-count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    right: -5px;
    top: -7px;
  }
  .header-contact-wrapper-title {
    font-weight: 500;
    font-size: 14px;
  }
}

@media (hover: hover) {
  .header-contact-wrapper-link:hover, .header-menu-links-item:hover {
    color: #e23881;
    transition: all 0.1s ease-in;
  }
}
</style>
