<template>
  <section class="layout-helper">
    <div>
      <slot name="content" />
    </div>
    <div class="layout-helper-wrapper">
      <div class="layout-helper-scroll">
        <p class="layout-helper-title">Нужна помощь?</p>
        <a class="layout-helper-link" href="tel:+996467448">
          <div class="layout-helper-image layout-helper-image-phone">
            <img class="layout-helper-icon" src="../../../../src/assets/icons/phone.png" alt="Телефон">
          </div>
          +996 467 448
        </a>
        <a class="layout-helper-link" target="_blank" href="https://t.me/gamehuborder">
          <div class="layout-helper-image layout-helper-image-telegram">
            <img class="layout-helper-icon" src="../../../../src/assets/icons/telegeram.png" alt="Telegram">
          </div>
          Telegram</a>
        <a class="layout-helper-link" target="_blank" href="https://wa.me/996220467448">
          <div class="layout-helper-image layout-helper-image-whatsapp">
            <img class="layout-helper-icon" src="../../../../src/assets/icons/whatsapp.png" alt="WhatsApp">
          </div>
          WhatsApp</a>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
  .layout-helper-scroll {
    position: sticky;
    top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
    background-color: #1d61e8;
    border-radius: 20px;
  }
  .layout-helper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 25px;
    justify-content: space-between;
  }
  .layout-helper-title {
    font-size: 20px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .layout-helper-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: white;
    font-weight: 500;
  }
  .layout-helper-icon {
    width: 22px;
    height: 22px;
  }
  .layout-helper-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout-helper-image-phone {
    background-color: #ef3284;
  }
  .layout-helper-image-whatsapp {
    background-color: #1ebea4;
  }
  .layout-helper-image-telegram {
    background: #35aade;
  }

  @media screen and (min-width: 1024px) {
    .layout-helper {
      gap: 95px;
    }
  }
</style>
