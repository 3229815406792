<template>
  <section class="contacts">
    <div class="container">
      <InfoDiePage title="Контакты" />
      <LayoutHelper>
        <template #content>
          <form @submit.prevent="sendDataForm" class="contacts-form">
            <h3 class="contacts-form-title">Обратная связь</h3>
            <div class="contacts-form-wrapper">
              <template v-if="!send">
                <input v-model="formData.name" class="contacts-form-input" placeholder="Ваше имя *" type="text" required>
                <input v-model="formData.email" class="contacts-form-input" placeholder="Ваш email *" type="email" required>
                <textarea v-model="formData.message" class="contacts-form-textarea" placeholder="Сообщение *" required/>
                <button type="submit" class="contacts-form-button">
                  <Spinner v-if="fetch" />
                  <template v-else>Отправить</template>
                </button>
              </template>
              <template v-else>
                <div class="contacts-form-success">
                  <p class="contacts-form-success-title">Благодарим за заявку!<br>
                    Наш менеджер перезвонит через 15 минут.
                  </p>
                </div>
              </template>
            </div>
            <div class="contacts-mission">
              <h3 class="contacts-mission-title">Миссия наша в акценте выгодных предложениях!</h3>
              <p class="contacts-mission-subtitle">"GamesStoreFun – это не просто магазин игр. Это твоя новая игровая вселенная, где ты можешь погрузиться в мир увлекательных приключений, найти единомышленников и открыть для себя что-то новое. Мы предлагаем широкий ассортимент игр, консолей и аксессуаров, чтобы каждый геймер мог создать свой идеальный игровой мир. Присоединяйся к нашему сообществу и давай играть вместе!"</p>
            </div>
          </form>
        </template>
      </LayoutHelper>
    </div>
  </section>
</template>

<script lang="ts" setup>
import LayoutHelper from '@/components/universal/LayoutHelper/indexC.vue'
import InfoDiePage from '@/components/universal/InfoDieSelectPage/index.vue'
import Spinner from '@/components/universal/Spinner/index.vue'
import {tgBotAction, tgBotSender} from '@/api/telegram/index'
import {onMounted, ref} from 'vue'

const formData = ref({
  title: 'Обратная связь',
  name: '',
  email: '',
  message: '',
  data: []
})

const fetch = ref(false)
const send:any = ref(false)

const sendDataForm = async () => {
  fetch.value = true
  try {
    const data = await tgBotSender(formData.value);
    fetch.value = false
    send.value = data
  } catch (error) {
    console.error('Error in sendDataForm:', error);
    fetch.value = false
  }
};

onMounted(() => {
  tgBotAction( 'Перешли на страницу контакты')
})

</script>

<style lang="scss" scoped>
  .contacts {
    margin: 40px 0;
  }
  .contacts-form-title {
    color: #242424;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .contacts-form-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
  .contacts-form-input {
    width: 100%;
  }
  .contacts-form-textarea {
    grid-row: 3/3;
    grid-column: 1/-1;
    height: 150px;
  }
  .contacts-form-button {
    grid-row: 4/4;
    grid-column: 1/-1;
    margin-top: 10px;
    background-color: #e23881;
    border-radius: 8px;
    padding: 15px 0;
    color: white;
    font-weight: 500;
  }
  .contacts-form-input, .contacts-form-textarea {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 15px;
    border: none;
    outline: 1px solid #989ca3;
    border-radius: 5px;
    transition: all 0.1s ease-in;
    &:focus {
      color: #242424;
      font-weight: 500;
      transition: all 0.1s ease-in;
      outline: 2px solid #e23881;
    }
  }
  .contacts-mission {
    margin: 40px 0 0 0;
  }
  .contacts-mission-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .contacts-mission-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (min-width: 1024px) {
    .contacts-form-wrapper {
      gap: 10px;
    }
    .contacts-form-button {
      margin-top: 20px;
    }
    .contacts-form-title, .contacts-mission-title {
      margin-bottom: 20px;
      font-size: 24px;
    }
    .contacts-mission-subtitle {
      font-size: 18px;
    }
    .contacts-form-success {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 110px 20px;
      background-color: #1d61e8;
      border-radius: 20px;
    }
    .contacts-form-success-title {
      color: white;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      line-height: 27px;
    }
  }
</style>
