<template>
  <section class="catalog">
     <div class="container">
       <div class="catalog-wrapper">
         <h3 class="catalog-title"><span class="catalog-title-span">Каталог игр</span></h3>
         <div class="catalog-buttons">
           <button title="фильтры" @click.stop="switcher.changeSideBarFilter()" class="catalog-icon">
             <img class="catalog-icon-image" src="../../../../src/assets/icons/filter.png" alt="Фильтры">
             фильтры
           </button>
           <button v-if="Array.isArray(store.itemsFilterCatalog) && store.itemsFilterCatalog.length || !store.isFilterStatus" title="Очистить фильтры"
                   @click.stop="store.clearFilterCatalog" class="catalog-icon catalog-icon-clear">
             <img class="catalog-icon-image-clear" src="../../../../src/assets/icons/clear-filters.png" alt="Фильтры">
           </button>
         </div>
       </div>
       <div
           v-if="!(Array.isArray(store.itemsFilterCatalog) && store.itemsFilterCatalog.length) && !store.isFilterStatus"
           class="catalog-filter-empty">
         <p class="catalog-filter-empty-title">По таким параметрам ничего не нашлось</p>
       </div>
       <div class="catalog-cards">
         <template v-for="(item, index) in list"
                   :key="index">
           <CardMain :item="item" />
         </template>
       </div>
     </div>
  </section>
</template>

<script lang="ts" setup>
import CardMain from '@/components/universal/Cards/MainC.vue'
import { useCatalogStore } from '@/store/catalog'
import { useSwitcherStore } from "@/store/switcher";
import { computed } from "vue";
const store = useCatalogStore()
const switcher = useSwitcherStore()

const list = computed(() => {
  if (!store.isFilterStatus && Array.isArray(store.itemsFilterCatalog) && store.itemsFilterCatalog.length) {
    return store.itemsFilterCatalog || []
  } else {
    return store.items || []
  }
})


const closeFilter = () => {
  store.changeFilterActive(false)
}

</script>

<style lang="scss" scoped>

.catalog {
  margin: 20px 0
}
.catalog-title {
  font-size: 24px;
  font-weight: 600;
  color: #242424;
}
.catalog-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.catalog-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.catalog-icon {
  background: #1d61e8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 7px 10px;
  border-radius: 10px;
  color: white;
  font-size: 14px;
}
.catalog-icon-clear {
  background-color: transparent;
  border: 1px solid red;
}
.catalog-icon-image {
  width: 20px;
  height: 20px;
}
.catalog-icon-image-clear {
  width: 20px;
  height: 20px;
}
.catalog-buttons {
  display: flex;
  align-items: center;
  gap: 7px;
}
.catalog-filter-empty {
  padding: 30px 0;
  background-color: #e3f0ff;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.catalog-filter-empty-title {
  font-size: 15px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .catalog {
    margin: 40px 0;
  }
  .catalog-wrapper {
    margin-bottom: 30px;
  }
  .catalog-icon {
    //width: 30px;
    //height: 30px;
  }
  .catalog-icon-image {
    width: 24px;
    height: 24px;
  }
  .catalog-title {
    font-size: 28px;
    font-weight: 600;
  }
  .catalog-cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .catalog-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1440px) {
  .catalog-cards {
    grid-template-columns: repeat(5, 1fr);
  }
}

</style>
