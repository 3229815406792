
export function calculateDiscountedPrice (originalPrice:number, discountPercent:number) {
  const discountAmount = (originalPrice * discountPercent) / 100
  const discountedPrice = originalPrice - discountAmount
  return discountedPrice
}

// СКРОЛЛИМ ДО ЭЛЕМЕНТА
export const scrollToElement = (payload: any) => {
  const { type, element, offset } = payload
  const targetElement = type === 'id' ? document.getElementById(element) : document.querySelector(`.${element}`)
  if (targetElement) {
    // Получаем координату Y относитель но верхней части страницы
    const yCoordinate = targetElement.getBoundingClientRect().top + window.pageYOffset - offset
    window.scrollTo({
      top: yCoordinate,
      behavior: 'smooth'
    })
  } else {
    console.error(`utils/helpers: scrollToElementID: ${element} не найден`)
  }
}
