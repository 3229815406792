<template>
  <section class="checkout">
    <div class="container">
      <InfoDiePage title="Офформление заказа" />
      <div class="checkout-wrapper" :style="Array.isArray(catalogTrash) && catalogTrash.length ? '' : 'grid-template-columns: repeat(1, 1fr)'">
        <div class="checkout-form">
          <h2 v-if="Array.isArray(catalogTrash) && catalogTrash.length" class="checkout-form-title">Адрес доставки и данные получателя</h2>
          <template v-if="!send">
            <form @submit.prevent="sendDataFormForTelegram" v-if="Array.isArray(catalogTrash) && catalogTrash.length">
              <div class="checkout-form-input-wrapper">
                <p class="checkout-form-input-title">Имя *</p>
                <input v-model="dataForm.name" class="checkout-form-input" type="text" required>
              </div>
              <div class="checkout-form-input-wrapper">
                <label for="game-selection" class="checkout-form-input-label">Страна/регион:</label>
                <select id="game-selection" v-model="dataForm.country" class="checkout-form-input checkout-form-select">
                  <option value="Кыргызстан">Кыргызстан</option>
                  <option value="Россия">Россия</option>
                </select>
              </div>
              <div class="checkout-form-input-wrapper">
                <p class="checkout-form-input-title">Город *</p>
                <input v-model="dataForm.city" class="checkout-form-input" type="text" required>
              </div>
              <div class="checkout-form-input-wrapper">
                <p class="checkout-form-input-title">Адрес *</p>
                <input v-model="dataForm.address" class="checkout-form-input" type="text" required>
              </div>
              <div class="checkout-form-input-wrapper">
                <p class="checkout-form-input-title">Телефон *</p>
                <input v-model="dataForm.phone" class="checkout-form-input" type="text" required>
              </div>
              <div class="checkout-form-input-wrapper">
                <p class="checkout-form-input-title">Примечание к заказу *</p>
                <textarea
                    v-model="dataForm.message"
                    placeholder="Просьба указать, каким способом с Вами связаться"
                    class="checkout-form-input checkout-form-textarea"
                    type="text" required />
              </div>
              <div class="checkout-form-checkbox-wrapper">
                <input type="checkbox" id="subscribe" name="subscribe" checked>
                <label class="checkout-form-checkbox-wrapper-label" for="subscribe"> Я прочитал(а) и принимаю
                  <router-link class="checkout-form-checkbox-wrapper-link" to="/delivery/" target="_blank">правила и условия</router-link> сайта *</label>
              </div>
              <button class="checkout-form-send">
                <Spinner v-if="fetch" />
                <template v-else>Подтвердить заказ</template>
              </button>
            </form>
          </template>
          <template v-else>
            <div class="checkout-success">
              <p class="checkout-success-title">Благодарим за заявку!<br>
                Наш менеджер перезвонит через 15 минут.
              </p>
            </div>
          </template>
        </div>
        <div class="checkout-info">
          <div>
            <p v-if="Array.isArray(catalogTrash) && catalogTrash.length"  class="checkout-info-title">Вы покупаете:</p>
            <div class="checkout-info-trash">
              <template v-if="Array.isArray(catalogTrash) && catalogTrash.length">
                <TrashCard
                    v-for="(card, index) in catalogTrash"
                    :key="index"
                    :item="card"
                    :change-side-bar-trash="false"
                />
              </template>
              <template v-else-if="(Array.isArray(catalogTrash) && catalogTrash.length) || !send">
                <div class="checkout-info-trash-empty">
                  <p class="checkout-info-trash-empty-p">Ваш заказ пустой</p>
                  <router-link class="checkout-info-trash-empty-link" to="/">Вернуться в магазин</router-link>
                </div>
              </template>
            </div>
            <p  v-if="Array.isArray(catalogTrash) && catalogTrash.length" class="checkout-info-subtitle checkout-info-delivery">Доставка: <span class="checkout-info-delivery-span">В зависимости от адреса</span></p>
            <div  v-if="Array.isArray(catalogTrash) && catalogTrash.length" class="checkout-info-subtitle checkout-info-sum">
              Итог:
              <div class="checkout-info-sum-span-total-wrapper">
              <span class="checkout-info-sum-span checkout-info-sum-span-total"
                    :style="totalPriceDiscount ? 'text-decoration: line-through' : ''">
              {{ totalPrice.toLocaleString() }}
              {{ totalPriceDiscount ? '' : switcher.country === 'kg' ? 'сом' : 'руб' }}
            </span>
                <span
                    v-if="totalPriceDiscount"
                    class="checkout-info-sum-span checkout-info-sum-span-sale">
                {{ totalPriceDiscount.toLocaleString() }}
                {{ switcher.country === 'kg' ? 'сом' : 'руб' }}
            </span>
              </div>
            </div>
          </div>
          <div class="checkout-info-delivery-wrapper">
            <p class="checkout-info-delivery-title"></p>
            <Accardeon :items="dataAccardeon" title="Условия доставки и оплаты"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Accardeon from '@/components/universal/Accardeon/index.vue'
import TrashCard from '@/components/universal/Cards/Trash.vue'
import InfoDiePage from '@/components/universal/InfoDieSelectPage/index.vue'
import Spinner from '@/components/universal/Spinner/index.vue'
import {onMounted, reactive, ref} from 'vue'
import { useCatalogStore } from "@/store/catalog";
import { computed } from "vue";
import { calculateDiscountedPrice } from "@/utils/helpers";
import { useSwitcherStore } from "@/store/switcher";
import {tgBotAction, tgBotSender} from "@/api/telegram";
const store = useCatalogStore()
const switcher = useSwitcherStore()
const catalogTrash = computed(() => store.itemsTrash)
const fetch = ref(false)
const send:any = ref(false)

const dataForm = reactive({
  title: 'Заявка на покупку',
  name: '',
  country: '',
  city: '',
  address: '',
  message: '',
  data: {},
  price: 0,
  priceSales: 0
})

const dataAccardeon = [
  {
    id: 0,
    title: 'Доставка по г.Бишкек',
    text: 'Доставка по городу Бишкек осуществляется в день оформления доставки с 10-19:00 - бесплатно!'
  },
  {
    id: 1,
    title: 'Доставка по Кыргызстану',
    text: 'Доставка по Кыргызстану осуществляется на следующий день курьерской службой Ылдам Экспресс'
  },
  {
    id: 2,
    title: 'Доставка по России',
    text: 'Доставка по Россию осуществляется курьерской службой СДЕК, срок доставка от 3-15 дней'
  },
  {
    id: 3,
    title: 'Оплата',
    text: 'Оплата наличными доступна только по г. Бишкек. За пределами города Бишкек оплата принимается посредством безналичного перевода на электронные кошельки и карты.'
  },
  {
    id: 3,
    title: 'Остались еще вопросы?',
    text: 'По другим воросам свяжитесь с нами в ' +
        '<a href="https://wa.me/996220467448" style="color: #1d61e8" target="_blank">WhatsApp</a> или <a href="https://t.me/gameshubshop" style="color: #1d61e8" target="_blank">Telegram</a>'
  },
]

const totalPrice = computed(() => catalogTrash.value.reduce((sum:number, item:any) => sum + item.info.price[switcher.country], 0))

const totalPriceDiscount = computed(() => {
  return catalogTrash.value.reduce((sum:number, item:any) => {
    const discountedPrice = calculateDiscountedPrice(item.info.price[switcher.country], item.params?.sale)
    return sum + discountedPrice
  }, 0)
})

onMounted(() => {
  tgBotAction( 'Перешли на страницу офформление закака')
})

const sendDataFormForTelegram = async () => {
  dataForm.data = catalogTrash.value
  dataForm.price = totalPrice.value
  dataForm.priceSales = totalPriceDiscount.value
  fetch.value = true
  try {
    const data = await tgBotSender(dataForm);
    catalogTrash.value.forEach((item:any) => store.inTrash(item.id))
    fetch.value = false
    send.value = data
  } catch (error) {
    console.error('Error in sendDataForm:', error);
    fetch.value = false
  }
}
</script>

<style lang="scss" scoped>
  .checkout {
    margin: 30px 0;
  }
  .checkout-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .checkout-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .checkout-form {
    margin-top: 30px;
  }
  .checkout-info-delivery {
    margin-top: 30px;
  }
  .checkout-form-title, .checkout-info-title, .checkout-info-delivery-title, .checkout-info-trash-empty-p, .checkout-info-trash-empty-link {
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 18px;
  }
  .checkout-info-trash-empty-link {
    text-decoration: underline;
    color: #1d61e8;
  }
  .checkout-form-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
  }
  .checkout-form-input-title {
    font-weight: 400;
    font-size: 16px;
  }
  .checkout-form-checkbox-wrapper-label, .checkout-form-checkbox-wrapper-link {
    font-weight: 400;
    font-size: 16px;
  }
  .checkout-form-checkbox-wrapper-label {
    margin-left: 5px;
    line-height: 22px;
  }
  .checkout-form-checkbox-wrapper-link {
    color: #e23881;
  }
  .checkout-form-input {
    padding: 9px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    &:focus {
      outline: 1px solid #e23881;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .checkout-form-select {
    appearance: none; /* Убираем стандартную стрелочку */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  .checkout-form-textarea {
    height: 150px;
  }
  .checkout-form-send {
    margin-top: 20px;
    padding: 15px 0;
    text-align: center;
    width: 100%;
    background-color: #e23881;
    color: white;
    font-weight: 500;
    border-radius: 7px;
  }

//  INFO
  .checkout-info-subtitle {
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .checkout-info-trash {
    display: flex;
    flex-direction: column;
  }
  .checkout-info-sum-span-sale {
    color: #e23881;
  }
  .checkout-info-sum-span-total-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .checkout-info-trash-empty {
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .checkout-info-sum-span {
    font-weight: 600;
  }
  @media screen and (min-width: 768px) {
    .checkout-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
    .checkout-form {
      margin-top: 0;
    }
  }

  .checkout-success {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 110px 20px;
    background-color: #1d61e8;
    border-radius: 20px;
  }
  .checkout-success-title {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    line-height: 27px;
  }
  @media screen and (min-width: 1440px) {
    .checkout-wrapper {
      gap: 50px;
    }
    .checkout-form-title, .checkout-info-title {
      font-size: 22px;
    }
    .checkout-form-input-title, .checkout-form-input-label {
      font-size: 18px;
    }
    .checkout-form-input {
      padding: 12px;
    }
    .checkout-info-subtitle {
      font-size: 18px;
    }
  }
</style>
