const sendPath = `https://api.telegram.org/bot1132371708:AAHevqmoVqQcWoLkOx76-RMSo__qxminK9k/sendMessage`;
const chatId = '-1002237191698'; // Замените на фактический идентификатор чата

export const tgBotSender = async (formData: any) => {
    const url = `${sendPath}?chat_id=${chatId}&text=${encodeURIComponent(formData.data.length ? formatTextFormCheckout(formData) : formatTextFormAnother(formData))}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.ok) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, 1000);
            });
        } else {
            console.error('Ошибка при отправке сообщения:', data);
            throw new Error('Ошибка при отправке сообщения');
        }
    } catch (error) {
        console.error('Ошибка сети:', error);
        throw error;
    }
};

export const tgBotAction = async (action:any, props:any = {}) => {
    const result = `📌 ${action}\n\n🎮 | ${props.platform} | ${props.name} | ${props.price} | ${props.country}`

    const url = `${sendPath}?chat_id=${chatId}&text=${encodeURIComponent(result)}`
    if (process.env.NODE_ENV === 'production') {
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.ok) {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(true);
                    }, 1000);
                });
            } else {
                console.error('Ошибка при отправке сообщения:', data);
                throw new Error('Ошибка при отправке сообщения');
            }
        } catch (error) {
            console.error('Ошибка сети:', error);
            throw error;
        }
    }
}



const formatTextFormAnother = (data: any) => {
   return `
--------------------------------------
   📌 ${data.title}\n\nИмя: ${data.name}\n\nТелефон: ${data.phone}\n\nEmail: ${data.email}\n\nСообщение: ${data.message}
--------------------------------------
   `
}

const formatTextFormCheckout = (data: any) => {
    const country = data.country === 'Кыргызстан' ? 'kg' : 'ru'
    const messageText = `
--------------------------------------
📌 ${data.title}\n\n
Имя: ${data.name}
Страна: ${data.country}
Город: ${data.city}
Адрес: ${data.address}
Сообщение: ${data.message}\n\n
🎮 Игры:\n
${data.data.map((item:any) => `${item.params.platform.toUpperCase()} | ${item.info.name} | ${item.info.price[country]} ${data.country === 'Кыргызстан' ? 'сом' : 'руб'}`).join('\n')}\n\n
💰: ${data.price} ${data.country === 'Кыргызстан' ? 'сом' : 'руб'}
💰🔥: ${data.priceSales} ${data.country === 'Кыргызстан' ? 'сом' : 'руб'}\n\n
📞:  ${data.phone}
--------------------------------------
`;
    return messageText
}
