<template>
  <section class="side-bar-trash" @click.self="switcher.changeSideBarTrash">
    <div class="side-bar-trash-block">
      <div>
        <div class="side-bar-trash-top">
          <button @click.stop="switcher.changeSideBarTrash" class="side-bar-trash-top-close">
            <svg class="side-bar-trash-top-close-icon" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
              <path d="M9.63599 22.364L22.3639 9.63603" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round">
              </path>
              <path d="M9.63599 9.63602L22.3639 22.3639" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path></svg>
          </button>
          <h4 class="side-bar-trash-top-title">Корзина</h4>
        </div>
        <div class="side-bar-trash-middle">
          <template v-if="Array.isArray(catalog) && catalog.length > 0">
            <div class="side-bar-trash-middle-card">
              <TrashCard
                  v-for="(card, index) in catalog"
                  :key="index"
                  :item="card" />
            </div>
          </template>
          <template v-else>
            <div class="side-bar-trash-middle-empty">
              <h3 class="side-bar-trash-middle-empty-info">Ваша корзина пустая</h3>
            </div>
          </template>
        </div>
      </div>
      <div v-if="Array.isArray(catalog) && catalog.length > 0" class="side-bar-trash-bottom">
        <p class="side-bar-trash-bottom-total">
          Итог:
          <span class="side-bar-trash-bottom-total-span" :style="totalPriceDiscount ? 'text-decoration: line-through' : ''">
            {{ totalPrice.toLocaleString() }}
            {{ totalPriceDiscount ? '' : switcher.country === 'kg' ? 'сом' : 'руб' }}
          </span>
          <span v-if="totalPriceDiscount" class="side-bar-trash-bottom-total-span side-bar-trash-bottom-total-span-sale">
            {{ totalPriceDiscount.toLocaleString() }}
            {{ switcher.country === 'kg' ? 'сом' : 'руб' }}
          </span>
        </p>
        <div class="side-bar-trash-bottom-buttons">
          <router-link to="/checkout/" @click.stop="
          () => {
           switcher.changeSideBarTrash();
  tgBotAction( 'Кнопка: Оформление заказа')
         }" class="side-bar-trash-bottom-button side-bar-trash-bottom-order">Оформление заказа</router-link>
        </div>
      </div>
      <button v-else class="side-bar-trash-middle-empty-close"  @click.self="switcher.changeSideBarTrash">Закрыть</button>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useSwitcherStore } from '@/store/switcher'
import { computed } from 'vue'
import { useCatalogStore } from '@/store/catalog'
import { useRouter } from 'vue-router'
import { calculateDiscountedPrice } from '@/utils/helpers'
import { tgBotAction } from '@/api/telegram/'
import TrashCard from '@/components/universal/Cards/Trash.vue'
const router = useRouter()
const switcher = useSwitcherStore()
const store = useCatalogStore()

const catalog = computed(() => store.itemsTrash)
const totalPrice = computed(() => catalog.value.reduce((sum:number, item:any) => sum + item.info.price[switcher.country], 0))

const totalPriceDiscount = computed(() => {
  return catalog.value.reduce((sum:number, item:any) => {
    const discountedPrice = calculateDiscountedPrice(item.info.price[switcher.country], item.params?.sale)
    return sum + discountedPrice
  }, 0)
})

const transitionToCard = (item:any) => {
  const type = item?.params?.type === 'игра' ? 'games' : 'product'
  router.push(`/catalog/${item?.params?.platform}/${type}/${item?.id}`)
  switcher.changeSideBarTrash()
}

</script>

<style lang="scss" scoped>
  .side-bar-trash {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: flex-end;
    z-index: 99;
  }
  .side-bar-trash-block {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    right: -300px; /* Скрыто изначально за левым краем */
    transition: right 0.3s ease; /* Плавный переход */
    width: 300px;
    padding: 0 0 10px 0;
  }
  .side-bar-trash-block-active {
    right: 0;
    transition: right 0.3s ease; /* Плавный переход */
  }
  .side-bar-trash-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    margin-bottom: 10px;
  }
  .side-bar-trash-top-close {
    position: absolute;
    top: 0;
    left: 0;
    background: #e23881;
    width: 65px;
    height: 43px;
    border-radius: 0 0 10px 0;
  }
  .side-bar-trash-top-close-icon {
    width: 30px;
    height: 30px;
  }
  .side-bar-trash-top-title {
    font-weight: 500;
    font-size: 20px;
    color: #242424;
  }
  .side-bar-trash-bottom-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    border-top: 1px solid #e7dddd;
    border-bottom: 1px solid #e7dddd;
    padding: 20px 10px;
  }
  .side-bar-trash-bottom-total-span {
    color: #242424;
    font-weight: 600;
    font-size: 20px;
  }
  .side-bar-trash-bottom-total-span-sale {
    color: #e23881;
  }
  .side-bar-trash-bottom-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .side-bar-trash-bottom-button, .side-bar-trash-middle-empty-close {
    font-weight: 500;
    font-size: 14px;
    border-radius: 7px;
    text-transform: uppercase;
    padding: 15px 0;
    margin: 0 10px;
  }
  .side-bar-trash-bottom-view, .side-bar-trash-middle-empty-close {
    background-color: #ffe0f0;
    color: #ef689a;
  }
  .side-bar-trash-bottom-order {
    background-color: #ef3284;
    color: white;
    text-align: center;
  }
  .side-bar-trash-middle-empty {
    min-height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .side-bar-trash-middle-empty-info {
    font-weight: 500;
    font-size: 18px;
  }
  .side-bar-trash-middle-card {
    overflow: scroll;
    height: calc(80vh - 50px);
    &::-webkit-scrollbar {
      width: 0;
    }
  }
</style>
