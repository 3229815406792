<template>
  <section class="side-bar-menu-bottom">
    <div class="container">
      <div class="side-bar-menu-bottom-wrapper">
        <button  @click.stop="switcher.changeSideBarMenu" class="side-bar-menu-bottom-link">
          <img class="side-bar-menu-icon" src="../../../../src/assets/icons/menu.png" alt="Главная">
          <p class="side-bar-menu-bottom-link-title">Меню</p>
        </button>
        <router-link class="side-bar-menu-bottom-link" to="/">
          <img class="side-bar-menu-icon" src="../../../../src/assets/icons/home.png" alt="Главная">
          <p class="side-bar-menu-bottom-link-title">Домой</p>
        </router-link>
        <button class="side-bar-menu-bottom-link side-bar-menu-icon-wrapper">
          <span v-if="Array.isArray(catalog) && catalog.length" class="side-bar-menu-icon-count">{{ catalog.length }}</span>
          <img class="side-bar-menu-icon"  @click.stop="switcher.changeSideBarTrash" src="../../../../src/assets/icons/shop-cart-black.png" alt="Корзинка">
          <p class="side-bar-menu-bottom-link-title">Заказ</p>
        </button>
        <button class="side-bar-menu-bottom-link side-bar-menu-icon-wrapper">
          <img class="side-bar-menu-icon"  @click.stop="switcher.changeSideBarFilter" src="../../../../src/assets/icons/filter-black.png" alt="Корзинка">
          <p class="side-bar-menu-bottom-link-title">Фильтры</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useSwitcherStore } from '@/store/switcher'
import { useCatalogStore } from '@/store/catalog'
import { computed } from "vue";
const switcher = useSwitcherStore()
const store = useCatalogStore()
const catalog = computed(() => store.itemsTrash)
</script>

<style lang="scss" scoped>
.side-bar-menu-bottom-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-bar-menu-bottom-link-title {
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
}
  .side-bar-menu-bottom {
    display: flex;
    position: sticky;
    bottom: 0;
    background: #e3f0ff;
    padding: 10px 0;
    z-index: 98;
    border-radius: 20px 20px 0 0;
  }
  .side-bar-menu-icon {
    width: 24px;
    height: 24px;
  }
  .side-bar-menu-bottom-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .side-bar-menu-icon-wrapper {
    position: relative;
  }
  .side-bar-menu-icon-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e23881;
    color: white;
    font-size: 11px;
    position: absolute;
    top: -5px;
    right: 35%;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }
  @media screen and (min-width: 1024px){
    .side-bar-menu-bottom {
      display: none;
    }
  }
</style>
