import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPages from '@/pages/MainPage.vue'
import DeliveryPages from '@/pages/Delivery/DeliveryPage.vue'
import ContactsPages from '@/pages/Contacts/ContactsPage.vue'
import CardID from '@/pages/Catalog/CardID.vue'
import CheckoutPage from '@/pages/Checkout/CheckoutPage.vue'
import CheckAvailabilityPage from '@/pages/CheckAvailability/CheckAvailabilityPage.vue'
import NotFound from '@/pages/NotFound/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: MainPages
  },
  {
    path: '/delivery/',
    name: 'DeliveryPages',
    component: DeliveryPages
  },
  {
    path: '/catalog/:platform/:type/:id',
    name: 'CardID',
    component: CardID,
    props: true
  },
  {
    path: '/contacts/',
    name: 'ContactsPages',
    component: ContactsPages
  },
  {
    path: '/checkout/',
    name: 'CheckoutPage',
    component: CheckoutPage
  },
  {
    path: '/check-availability/',
    name: 'CheckAvailabilityPage',
    component: CheckAvailabilityPage
  },
  {
    path: '/:pathMatch(.*)*', // Маршрут, который будет перехватывать все неизвестные пути
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Если есть сохраненная позиция, например при нажатии кнопки "назад"
    if (savedPosition) {
      return savedPosition;
    } else {
      // Иначе прокрутите к верхней части страницы
      return { top: 0 };
    }
  },
})

export default router
