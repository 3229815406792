import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import '@/assets/sass/index.scss'

import { createPinia } from 'pinia'
// Создание экземпляра Pinia
const pinia = createPinia()
const head = createHead()

createApp(App).use(router).use(head).use(pinia).mount('#app')
