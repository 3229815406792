<template>
  <section class="search-input">
    <input v-model="searchText" @input="store.searchTextInCatalog(searchText)" class="search-input-input" type="text" placeholder="Поиск товаров">
    <div v-if="openResult" class="search-input-result">
      <TrashCard
          v-for="(card, index) in resultCatalogSearch"
          :key="index"
          :item="card"
          :btn-delete="false"
          :change-side-bar-trash="false"
          @onClickCard="store.searchTextInCatalog(''); searchText = ''"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import {computed, ref, onMounted, onUnmounted, watch} from 'vue'
import { useCatalogStore } from '@/store/catalog'
import TrashCard from '@/components/universal/Cards/Trash.vue'
const store = useCatalogStore()
const searchText = ref('')
const openResult = ref(false)
const resultCatalogSearch = computed(() => store.itemsSearchCatalog)

watch(() => resultCatalogSearch.value, (newResult) => {
  if (Array.isArray(newResult) && newResult.length) {
    openResult.value = true
  }  else {
    openResult.value = false
  }
}, { deep: true })

const handleClickOutside = (event:any) => {
  if (!event.target.classList.contains('search-input-input')) {
    openResult.value = false
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style lang="scss" scoped>
  .search-input {
    position: relative;
    height: 38px;
    min-width: 100%; /* Изменил на 200px для демонстрации */
    box-sizing: border-box; /* Учитываем padding и border в ширину элемента */
  }
  .search-input-input {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 20px;
    color: black;
  }
  .search-input-result {
    position: absolute;
    background-color: white;
    right: 0;
    left: 0;
    top: 45px;
    border-radius: 5px;
    max-height: 300px;
    min-height: auto;
    overflow-y: scroll;
    z-index: 99;
    -webkit-box-shadow: -1px 6px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px 6px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: -1px 6px 8px 0px rgba(34, 60, 80, 0.2);
    &::-webkit-scrollbar {
      width: 0;
    }
    .trash-card {
      border-top: none;
      border-bottom: 0;
    }
  }

  @media screen and (min-width: 576px) {
    .search-input {
      min-width: 340px; /* Изменил на 200px для демонстрации */
      max-width: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .search-input {
      min-width: 473px;
      max-width: 473px; /* Изменил на 200px для демонстрации */
    }
  }
</style>
