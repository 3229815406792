<template>
  <section>
    <BannerMain />
    <LastBuy />
    <CatalogMain />
<!--    <Reviews />-->
    <section class="accardeon-block">
     <Accardeon :items="dataAccardeon" custom-class="container"/>
    </section>
    <SideBarFilter v-if="switcher.sidebarFilterStatus" />
  </section>
</template>

<script setup lang="ts">
import BannerMain from '@/components/universal/Banners/BannerMain.vue'
import SideBarFilter from '@/components/universal/SideBarFilter/indexC.vue'
import CatalogMain from '@/components/universal/Catalog/indexC.vue'
import Accardeon from '@/components/universal/Accardeon/index.vue'
import LastBuy from '@/components/main/LastBuy.vue'
import { useSwitcherStore } from '@/store/switcher'
import {onMounted} from "vue";
import {tgBotAction} from "@/api/telegram";
const switcher = useSwitcherStore()

const dataAccardeon = [
  {
    id: 0,
    title: 'Физический диск vs. цифровой ключ',
    text: '<b>Полная собственность:</b><br>' +
        'Диск остается вашим навсегда. Вы можете продать его, обменять или подарить, чего нельзя сделать с цифровым ключом.<br>' +
        '<br>' +
        '<b>Экономия места на жестком диске:</b><br>' +
        'Установочные файлы игры на диске не занимают столько места на жестком диске приставки, как цифровая версия, что позволяет вам хранить больше игр одновременно.<br>' +
        '<br>' +
        '<b>Офлайн-доступ:</b><br>' +
        'Вы можете играть в свою игру, даже если у вас нет доступа к интернету, что особенно удобно для тех, кто живет в местах с нестабильным подключением.<br>' +
        '<br>' +
        '<b>Коллекционная ценность:</b><br>' +
        'Мы берем ваш заказ в обработку. Перед отправкой мы тщательно упаковываем товар, чтобы он прибыл к вам в идеальном состоянии.<br>' +
        '<br>' +
        '<b>Безопасность:</b><br>' +
        'С лицензионным диском нет риска потери доступа к игре из-за взлома аккаунта или других проблем, связанных с учетной записью.<br>' +
        '<br>' +
        '<b>Будущая продажа или обмен:</b><br>' +
        'В отличие от цифрового ключа, вы всегда можете продать или обменять диск, если игра вам больше не интересна. Это помогает вернуть часть потраченных средств или обменять игру на другую.<br>' +
        '<br>' +
        '<b>Игра с друзьями:</b><br>' +
        'Легко взять диск с собой и поиграть в любимую игру у друзей на их приставке, что делает ваши игровые вечера еще более увлекательными.'
  },
  {
    id: 1,
    title: 'Как идет обработка заказа?',
    text: '<b>Выбор товара и оформление заказа:</b><br>' +
        'Выбираете понравившуюся игру или товар на нашем сайте и добавляете его в корзину. Оформляете заказ, оставляя свои контактные данные и предпочитаемый способ связи с вами.<br>' +
        '<br>' +
        '<b>Связь с менеджером:</b><br>' +
        'Наш менеджер свяжется с вами для уточнения всех деталей заказа. Мы убедимся, что все данные введены корректно, и ответим на любые ваши вопросы.<br>' +
        '<br>' +
        '<b>Оплата:</b><br>' +
        'После подтверждения данных, вы оплачиваете товар удобным для вас способом, например, банковским переводом.<br>' +
        '<br>' +
        '<b>Обработка заказа:</b><br>' +
        'Мы берем ваш заказ в обработку. Перед отправкой мы тщательно упаковываем товар, чтобы он прибыл к вам в идеальном состоянии.<br>' +
        '<br>' +
        '<b>Фотоотчет:</b><br>' +
        'После упаковки мы предоставляем вам фотоотчет, подтверждающий, что ваш товар готов к отправке.<br>' +
        '<br>' +
        '<b>Доставка:</b><br>' +
        'Мы отправляем ваш заказ.<br>' +
        '<br>' +
        '<b>Получение товара:</b><br>' +
        'Вы принимаете товар у себя, наслаждаясь покупкой. Мы всегда рады вашим отзывам и готовы помочь в случае возникновения вопросов.'
  },
  {
    id: 2,
    title: 'Какие способы оплаты вы принимаете?',
    text: 'Мы принимаем разнообразные способы оплаты, включая банковские карты (Visa, MasterCard, Мир), электронные кошельки и безналичный расчет.'
  },
  {
    id: 3,
    title: 'Есть ли у вас скидки на игры?',
    text: 'Да, у нас регулярно проходят акции и распродажи, где вы можете приобрести игры по сниженным ценам. Следите за нашими обновлениями на сайте и в социальных сетях, чтобы не пропустить выгодные предложения.'
  },
  {
    id: 4,
    title: 'Доставка бесплатная?',
    text: '<b>По городу Бишкек:</b> Доставка бесплатная.<br> Срок доставки – в тот же день.<br>' +
        '<b>По России:</b> Отправляем компанией СДЭК. Доставка зависит от местомоложения <br> Стоимость доставки зависит от местоположения <br> Срок доставки – от 3 до 15 дней.<br>' +
        '<b>По Кыргызстану:</b> Отправляем через курьерскую службу Ылдам Экспресс.<br> Стоимость доставки зависит от местоположения <br> Срок доставки – от 1 до 2 дней.'
  },
]

onMounted(() => {
  tgBotAction( 'Новый посетитель на сайте')
})

</script>

<style lang="scss" scoped>
.accardeon-block {
  background: #e3f0ff;
  padding: 20px 0
}

@media screen and (min-width: 576px) {
  .accardeon-block {
    background: #e3f0ff;
    padding: 30px 0
  }
}
@media screen and (min-width: 1024px) {
  .accardeon-block {
    background: #e3f0ff;
    padding: 50px 0;
  }
}
</style>
