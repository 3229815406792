<template>
  <section class="accardeon">
    <div :class="customClass">
      <h3 class="accardeon-title">
        {{ title }}
      </h3>
      <div
          v-for="(item, index) in items"
          class="accardeon-item"
          @click="activeIndex = index"
      >
        <button :style="index === activeIndex ? 'color: #e23881' : ''" class="accardeon-item-button">
          {{ item.title }}
          <img class="accardeon-item-button-icon" src="@/assets/icons/top-arrow.png" alt="иконка"
               :style="index === activeIndex ? 'transform: rotate(360deg)' : 'transform: rotate(180deg)'">
        </button>
        <p
            v-if="index === activeIndex"
            v-html="item.text"
            class="accardeon-item-text"/>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import  { ref } from 'vue'
const props = defineProps({
  items: {
    type: Array,
    default: () => ([])
  },
  title: {
    type: String,
    default: 'Игровой FAQ'
  },
  customClass: {
    type: String,
    default: ''
  }
})
const activeIndex = ref(0)
</script>

<style lang="scss" scoped>
  .accardeon-title {
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .accardeon-item-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }
  .accardeon-item-button-icon {
    width: 18px;
    height: 18px;
  }
  .accardeon-item-text {
    font-weight: 500;
    font-size: 14px;
    margin: 20px 0 0 0;
    line-height: 24px;
  }
  .accardeon-item {
    margin-bottom: 30px;
  }
  .accardeon-item:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 768px) {
    .accardeon-title {
      font-size: 28px;
    }
    .accardeon-item-button {
      font-size: 22px;
    }
  }

  @media screen and (min-width: 1440px) {
    .accardeon-item-text {
      font-size: 16px;
    }
    .accardeon-item-button-icon {
      width: 20px;
      height: 20px;
    }
  }
  @media (hover: hover) {
    .accardeon-item-button:hover {
      color: #e23881;
      transition: all 0.2s ease-in;
    }
  }
</style>
