<template>
  <section class="check-availability">
    <div class="container">
      <InfoDiePage title="Уточнить наличие" />
      <p class="check-availability-title">Если не нашли нужную игру в нашем магазине "<b>GamesStore <span class="check-availability-title-span">Fun</span></b>", не переживайте!<br> Просто оставьте заявку, и наш менеджер оперативно свяжется с вами, чтобы предоставить всю необходимую информацию.<br> Мы всегда готовы помочь вам найти именно то, что вы ищете!</p>
      <LayoutHelper>
        <template #content>
          <form @submit.prevent="sendDataForm" class="check-availability-form">
            <h3 class="check-availability-form-title">Обратная связь</h3>
            <div class="check-availability-form-wrapper">
              <template v-if="!send">
                <input v-model="formData.name" class="check-availability-form-input" placeholder="Ваше имя *" type="text" required>
                <input v-model="formData.email" class="check-availability-form-input" placeholder="Ваш email *" type="email" required>
                <input v-model="formData.phone" class="check-availability-form-input" placeholder="Ваш телефон *" type="text" required>
                <textarea v-model="formData.message" class="check-availability-form-textarea" placeholder="Укажите игры *" required/>
                <button type="submit" class="check-availability-form-button">
                  <Spinner v-if="fetch" />
                  <template v-else>Отправить</template>
                </button>
              </template>
              <template v-else>
                <div class="check-availability-form-success">
                  <p class="check-availability-form-success-title">Благодарим за заявку!<br>
                    Наш менеджер перезвонит через 15 минут.
                  </p>
                </div>
              </template>
            </div>
          </form>
        </template>
      </LayoutHelper>
    </div>
  </section>
</template>

<script setup lang="ts">
import LayoutHelper from '@/components/universal/LayoutHelper/indexC.vue'
import InfoDiePage from '@/components/universal/InfoDieSelectPage/index.vue'
import Spinner from '@/components/universal/Spinner/index.vue'

import {tgBotAction, tgBotSender} from '@/api/telegram/'
import {onMounted, ref} from 'vue'

const formData = ref({
  title: 'Уточнить наличие',
  name: '',
  phone: '',
  email: '',
  message: '',
  data: []
})

const fetch = ref(false)
const send:any = ref(false)

const sendDataForm = async () => {
  fetch.value = true
  try {
    const data = await tgBotSender(formData.value);
    fetch.value = false
    send.value = data
  } catch (error) {
    console.error('Error in sendDataForm:', error);
    fetch.value = false
  }
};

onMounted(() => {
  tgBotAction( 'Перешли на страницу уточнить наличие')
})
</script>

<style lang="scss" scoped>
.check-availability {
  margin: 40px 0;
}
.check-availability-title {
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}
.check-availability-title-span {
  color: #e23881;
}
.check-availability-form-title {
  color: #242424;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.check-availability-form-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
}
.check-availability-form-input {
  width: 100%;
}
.check-availability-form-textarea {
  grid-row: 4/4;
  grid-column: 1/-1;
  height: 150px;
}
.check-availability-form-button {
  grid-row: 5/5;
  grid-column: 1/-1;
  margin-top: 10px;
  background-color: #e23881;
  border-radius: 8px;
  padding: 15px 0;
  color: white;
  font-weight: 500;
}
.check-availability-form-input, .check-availability-form-textarea {
  font-size: 16px;
  font-weight: 500;
  padding: 15px 15px;
  border: none;
  outline: 1px solid #989ca3;
  border-radius: 5px;
  transition: all 0.1s ease-in;
  &:focus {
    color: #242424;
    font-weight: 500;
    transition: all 0.1s ease-in;
    outline: 2px solid #e23881;
  }
}
</style>
