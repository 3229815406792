import { defineStore } from 'pinia'

export const useCatalogStore = defineStore('catalog', {
  state: ():any => ({
    catalog: [
      {
        "id": 0,
        "info": {
          "name": "Hogwarts Legacy",
          "price": {
            "ru": 2500,
            "kg": 3100
          },
          "description": "Окунитесь в волшебный мир Хогвартса и раскройте свои магические способности. Исследуйте знаменитую школу магии, сражайтесь с драконами и раскройте тайны, скрытые в её стенах. Эта игра предложит вам незабываемое приключение в мире волшебства и магии.",
          "image": "/img/catalog/ps4/hogwarts-legacy.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Hogwarts Legacy - Магическое Приключение на PS4",
          "description": "Исследуйте мир Хогвартса, сражайтесь с драконами и раскройте свои магические способности в игре Hogwarts Legacy на PS4. Купите сейчас и окунитесь в мир магии!",
          "keywords": "Hogwarts Legacy, PS4, волшебство, драконы, приключения, магия, игра",
          "robots": "index, follow",
          "og:title": "Hogwarts Legacy - Магическое Приключение на PS4",
          "og:description": "Присоединяйтесь к невероятному приключению в мире Хогвартса. Исследуйте школу магии, сражайтесь с драконами и раскройте свои способности в игре Hogwarts Legacy на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 1,
        "info": {
          "name": "Aliens: Dark Descent",
          "price": {
            "ru": 2600,
            "kg": 3800
          },
          "description": "Погрузитесь в мрачный и напряжённый мир игры Aliens: Dark Descent. Боритесь с инопланетными существами, исследуйте заброшенные космические станции и попытайтесь выжить в условиях постоянной опасности. Эта игра предложит вам незабываемые приключения в жанре научной фантастики и ужаса.",
          "image": "/img/catalog/ps4/aliens-dark-descent.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 5,
          "inTrash": false,
          "type": "игра",
          "genre": "ужасы",
          "platform": "ps4",
          "interface_language": "английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Aliens: Dark Descent - Напряжённый Хоррор на PS4",
          "description": "Боритесь с инопланетными угрозами и исследуйте заброшенные космические станции в игре Aliens: Dark Descent на PS4. Купите сейчас и испытайте настоящий ужас!",
          "keywords": "Aliens: Dark Descent, PS4, ужасы, инопланетяне, хоррор, научная фантастика, игра",
          "robots": "index, follow",
          "og:title": "Aliens: Dark Descent - Напряжённый Хоррор на PS4",
          "og:description": "Погрузитесь в мрачный и напряжённый мир Aliens: Dark Descent. Сражайтесь с инопланетными существами и выживайте в условиях постоянной опасности на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 2,
        "info": {
          "name": "Atomic Heart",
          "price": {
            "ru": 3300,
            "kg": 4200
          },
          "description": "Окунитесь в альтернативную реальность Советского Союза в игре Atomic Heart. Исследуйте футуристические лаборатории и военные базы, сражайтесь с роботами и мутантами, раскрывайте тайны научных экспериментов и наслаждайтесь захватывающим геймплеем. Эта игра предложит вам уникальный опыт в жанре шутера и RPG.",
          "image": "/img/catalog/ps4/atomic-heart.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "шутер",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Atomic Heart - Альтернативная Реальность на PS4",
          "description": "Исследуйте футуристические лаборатории и сражайтесь с роботами и мутантами в игре Atomic Heart на PS4. Купите сейчас и погрузитесь в уникальный шутер и RPG!",
          "keywords": "Atomic Heart, PS4, шутер, RPG, футуристические лаборатории, роботы, мутанты, игра",
          "robots": "index, follow",
          "og:title": "Atomic Heart - Альтернативная Реальность на PS4",
          "og:description": "Откройте для себя альтернативную реальность Советского Союза. Сражайтесь с роботами и мутантами, раскрывайте тайны научных экспериментов в игре Atomic Heart на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 3,
        "info": {
          "name": "Cyberpunk 2077",
          "price": {
            "ru": 2400,
            "kg": 2400
          },
          "description": "Погрузитесь в мрачный и захватывающий мир киберпанка в игре Cyberpunk 2077. Исследуйте мегаполис Найт-Сити, где высокие технологии соседствуют с социальной неустойчивостью. Станьте наемником по имени Ви и определите свою судьбу в этом открытом мире, полном возможностей и опасностей. Эта игра предложит вам увлекательный сюжет, множество квестов и непревзойденную графику.",
          "image": "/img/catalog/ps4/cyberpunk-2077.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "RPG",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2020,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Cyberpunk 2077 - Киберпанк Приключение на PS4",
          "description": "Исследуйте мегаполис Найт-Сити и станьте наемником Ви в игре Cyberpunk 2077 на PS4. Купите сейчас и окунитесь в мир киберпанка!",
          "keywords": "Cyberpunk 2077, PS4, киберпанк, RPG, открытый мир, наемник, Найт-Сити, игра",
          "robots": "index, follow",
          "og:title": "Cyberpunk 2077 - Киберпанк Приключение на PS4",
          "og:description": "Погрузитесь в мрачный и захватывающий мир киберпанка. Исследуйте Найт-Сити и станьте наемником Ви в игре Cyberpunk 2077 на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 4,
        "info": {
          "name": "Elden Ring",
          "price": {
            "ru": 3600,
            "kg": 4200
          },
          "description": "Погрузитесь в мрачный и таинственный мир Elden Ring, созданный в сотрудничестве Хидэтакой Миядзаки и Джорджем Р. Р. Мартином. Исследуйте обширные открытые пространства, сражайтесь с могущественными врагами и раскройте тайны древнего мира. Вас ждёт незабываемое приключение в жанре ролевого экшена с глубоким сюжетом и потрясающей атмосферой.",
          "image": "/img/catalog/ps4/elden-ring.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "RPG",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2022,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Elden Ring - Эпическое Приключение на PS4",
          "description": "Исследуйте мрачный и таинственный мир Elden Ring на PS4. Сражайтесь с могущественными врагами и раскройте древние тайны. Купите сейчас и погрузитесь в эпическое приключение!",
          "keywords": "Elden Ring, PS4, RPG, приключение, экшен, Хидэтака Миядзаки, Джордж Р. Р. Мартин, игра",
          "robots": "index, follow",
          "og:title": "Elden Ring - Эпическое Приключение на PS4",
          "og:description": "Погрузитесь в мрачный и таинственный мир Elden Ring. Сражайтесь с врагами и раскройте древние тайны в этом эпическом приключении на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 5,
        "info": {
          "name": "Horizon: Forbidden West",
          "price": {
            "ru": 2900,
            "kg": 3100
          },
          "description": "Отправляйтесь в увлекательное путешествие по Запрещенному Западу вместе с Элой в игре Horizon: Forbidden West. Исследуйте захватывающие постапокалиптические пейзажи, сражайтесь с механическими существами и раскройте тайны древних цивилизаций. Вас ждёт удивительный мир с невероятной графикой, глубокой историей и динамичным геймплеем.",
          "image": "/img/catalog/ps4/horizon-forbidden-west.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Horizon: Forbidden West - Путешествие по Запрещенному Западу на PS4",
          "description": "Исследуйте постапокалиптический мир и сражайтесь с механическими существами в игре Horizon: Forbidden West на PS4. Купите сейчас и отправьтесь в увлекательное путешествие с Элой!",
          "keywords": "Horizon: Forbidden West, PS4, приключения, постапокалипсис, Элой, механические существа, игра",
          "robots": "index, follow",
          "og:title": "Horizon: Forbidden West - Путешествие по Запрещенному Западу на PS4",
          "og:description": "Отправьтесь в увлекательное путешествие по Запрещенному Западу вместе с Элой. Исследуйте мир, сражайтесь с механическими существами и раскройте тайны древних цивилизаций в Horizon: Forbidden West на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 6,
        "info": {
          "name": "Lies of P",
          "price": {
            "ru": 4400,
            "kg": 4800
          },
          "description": "Погрузитесь в мрачный и интригующий мир игры Lies of P. Следуйте за Пиночеттом в его путешествии по загадочному городу, сражайтесь с ужасающими врагами и раскройте правду, скрытую за завесой лжи. Эта игра предложит вам захватывающий сюжет, глубокий геймплей и уникальную атмосферу, вдохновленную классической сказкой.",
          "image": "/img/catalog/ps4/lies-of-p.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps4",
          "interface_language": "английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Lies of P - Мрачное Приключение на PS4",
          "description": "Следуйте за Пиночеттом в его путешествии по загадочному городу в игре Lies of P на PS4. Купите сейчас и раскройте правду, скрытую за завесой лжи!",
          "keywords": "Lies of P, PS4, экшн, приключение, Пиночетт, мрачный мир, игра",
          "robots": "index, follow",
          "og:title": "Lies of P - Мрачное Приключение на PS4",
          "og:description": "Погрузитесь в интригующий мир Lies of P. Следуйте за Пиночеттом и раскройте правду, скрытую за завесой лжи в этой мрачной приключенческой игре на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 7,
        "info": {
          "name": "Mortal Kombat 11",
          "price": {
            "ru": 2100,
            "kg": 2000
          },
          "description": "Испытайте мощь легендарной бойцовской серии в игре Mortal Kombat 11. Овладейте уникальными способностями и фаталити каждого персонажа, участвуйте в захватывающих сражениях и узнайте продолжение эпической истории вселенной Mortal Kombat. Игра предлагает потрясающую графику, улучшенную механику боя и разнообразные игровые режимы.",
          "image": "/img/catalog/ps4/mortal-kombat-11.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 5,
          "inTrash": false,
          "type": "игра",
          "genre": "файтинг",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2019,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Mortal Kombat 11 - Легендарный Файтинг на PS4",
          "description": "Участвуйте в эпических сражениях и овладейте фаталити в игре Mortal Kombat 11 на PS4. Купите сейчас и испытайте мощь легендарной серии файтингов!",
          "keywords": "Mortal Kombat 11, PS4, файтинг, бойцовская игра, фаталити, эпические сражения, игра",
          "robots": "index, follow",
          "og:title": "Mortal Kombat 11 - Легендарный Файтинг на PS4",
          "og:description": "Испытайте мощь легендарной бойцовской серии. Участвуйте в эпических сражениях и овладейте фаталити в игре Mortal Kombat 11 на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 8,
        "info": {
          "name": "Stray",
          "price": {
            "ru": 2800,
            "kg": 3000
          },
          "description": "Откройте для себя уникальный мир игры Stray, в котором вы играете за бездомного кота, исследующего загадочный футуристический город. Путешествуйте по городским улицам, решайте головоломки и взаимодействуйте с окружающими существами, чтобы раскрыть тайны этого мира и найти путь домой. Stray предлагает увлекательный геймплей и атмосферный сюжет, который погрузит вас в захватывающее приключение.",
          "image": "/img/catalog/ps4/stray.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Stray - Приключение Без Домашнего Кота на PS4",
          "description": "Погрузитесь в уникальное приключение за бездомного кота в игре Stray на PS4. Исследуйте футуристический город и раскрывайте его тайны. Купите сейчас и отправьтесь в увлекательное путешествие!",
          "keywords": "Stray, PS4, приключения, бездомный кот, футуристический город, игра",
          "robots": "index, follow",
          "og:title": "Stray - Приключение Без Домашнего Кота на PS4",
          "og:description": "Откройте для себя уникальный мир Stray. Играй за бездомного кота, исследуй футуристический город и раскрывай его тайны в игре Stray на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 9,
        "info": {
          "name": "The Callisto Protocol",
          "price": {
            "ru": 3000,
            "kg": 4400
          },
          "description": "Погрузитесь в мир ужаса и выживания в игре The Callisto Protocol. Исследуйте заброшенную космическую тюрьму на спутнике Юпитера и сражайтесь с ужасающими существами в атмосфере постоянного страха и напряжения. Игра предлагает захватывающий сюжет, отличную графику и динамичную механику боя, которые заставят вас переживать каждое мгновение.",
          "image": "/img/catalog/ps4/the-callisto-protocol.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 30,
          "inTrash": false,
          "type": "игра",
          "genre": "ужасы",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "The Callisto Protocol - Ужасное Приключение на PS4",
          "description": "Погрузитесь в ужас и выживание в игре The Callisto Protocol на PS4. Исследуйте космическую тюрьму и сражайтесь с ужасными существами. Купите сейчас и испытайте атмосферу настоящего страха!",
          "keywords": "The Callisto Protocol, PS4, ужасы, космическая тюрьма, выживание, игра",
          "robots": "index, follow",
          "og:title": "The Callisto Protocol - Ужасное Приключение на PS4",
          "og:description": "Исследуйте заброшенную космическую тюрьму и сражайтесь с ужасными существами в игре The Callisto Protocol на PS4. Погружайтесь в мир ужаса и выживания.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 10,
        "info": {
          "name": "The Last of Us: Part II Special Edition",
          "price": {
            "ru": 4600,
            "kg": 4800
          },
          "description": "Погружайтесь в эпическое продолжение культовой серии с Special Edition игры The Last of Us: Part II. Следуйте за Элли в её путешествии по разрушенному миру, исследуйте новые локации, сражайтесь с жестокими врагами и переживайте трогательную историю, которая затронет самые глубины души. Специальное издание включает уникальные бонусы и коллекционные предметы для истинных поклонников.",
          "image": "/img/catalog/ps4/the-last-of-us-part-ii-special-edition.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps4",
          "interface_language": "английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2020,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "The Last of Us: Part II Special Edition - Эпическое Приключение на PS4",
          "description": "Откройте для себя Special Edition The Last of Us: Part II на PS4. Погружайтесь в трогательную историю Элли и наслаждайтесь уникальными бонусами. Купите сейчас и переживите эпическое приключение!",
          "keywords": "The Last of Us Part II, Special Edition, PS4, приключения, Элли, коллекционные предметы, игра",
          "robots": "index, follow",
          "og:title": "The Last of Us: Part II Special Edition - Эпическое Приключение на PS4",
          "og:description": "Погружайтесь в трогательную историю Элли и исследуйте разрушенный мир в Special Edition The Last of Us: Part II на PS4. Уникальные бонусы и коллекционные предметы ждут вас!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 11,
        "info": {
          "name": "Prince of Persia: The Lost Crown",
          "price": {
            "ru": 3900,
            "kg": 4690
          },
          "description": "Погружайтесь в захватывающее приключение с Prince of Persia: The Lost Crown. Вернитесь в мир древних тайн и магии, где вам предстоит стать свидетелем новых испытаний и загадок. Исследуйте богатые историей локации, сражайтесь с могущественными врагами и раскройте тайны потерянной короны. Игра предлагает динамичный геймплей и захватывающий сюжет, который привлечет как новых игроков, так и фанатов серии.",
          "image": "/img/catalog/ps4/prince-of-persia-the-lost-crown.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": '/img/catalog/banners/prince-of-persia-the-lost-crown-ps5.webp'
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Prince of Persia: The Lost Crown - Эпическое Приключение на PS4",
          "description": "Откройте для себя Prince of Persia: The Lost Crown на PS4. Исследуйте древние тайны, сражайтесь с врагами и раскройте секреты потерянной короны. Купите сейчас и погружайтесь в увлекательное приключение!",
          "keywords": "Prince of Persia The Lost Crown, PS4, приключения, потерянная корона, древние тайны, игра",
          "robots": "index, follow",
          "og:title": "Prince of Persia: The Lost Crown - Эпическое Приключение на PS4",
          "og:description": "Погружайтесь в захватывающее приключение с Prince of Persia: The Lost Crown. Исследуйте древние тайны, сражайтесь с врагами и раскройте секреты потерянной короны на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 12,
        "info": {
          "name": "Sekiro: Shadows Die Twice",
          "price": {
            "ru": 3790,
            "kg": 3790
          },
          "description": "Погружайтесь в мир самурайского экшена с Sekiro: Shadows Die Twice. Исследуйте мрачный и жестокий мир феодальной Японии, где вам предстоит стать мастером боевых искусств и сразиться с могущественными врагами. Используйте уникальные навыки и оружие, чтобы преодолеть сложные испытания и раскрыть тайны древнего Востока. Игра предлагает захватывающий геймплей и впечатляющую графику.",
          "image": "/img/catalog/ps4/sekiro-shadows-die-twice.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 5,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2019,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Sekiro: Shadows Die Twice - Самурайское Приключение на PS4",
          "description": "Погружайтесь в мир самурайского экшена с Sekiro: Shadows Die Twice на PS4. Сразитесь с могущественными врагами и раскрывайте тайны древнего Востока. Купите сейчас и испытайте захватывающий геймплей!",
          "keywords": "Sekiro Shadows Die Twice, PS4, экшн, самурайское приключение, феодальная Япония, игра",
          "robots": "index, follow",
          "og:title": "Sekiro: Shadows Die Twice - Самурайское Приключение на PS4",
          "og:description": "Откройте для себя мир самурайского экшена в Sekiro: Shadows Die Twice. Сразитесь с врагами и раскройте тайны древнего Востока на PS4.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 13,
        "info": {
          "name": "Marvel’s Spider-Man 2",
          "price": {
            "ru": 5300,
            "kg": 5200
          },
          "description": "Откройте для себя продолжение захватывающего приключения с Marvel’s Spider-Man 2. Исследуйте огромный и детализированный Нью-Йорк в роли Питера Паркера и Майлза Моралеса. Сражайтесь с новыми врагами, используйте уникальные способности обоих Человек-Пауков и раскрывайте тайны, которые угрожают городу. Игра предлагает улучшенную графику, новые возможности и ещё больше акробатических трюков.",
          "image": "/img/catalog/ps5/marvels-spider-man-2.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 5,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Marvel’s Spider-Man 2 - Приключение Человек-Паук на PS5",
          "description": "Исследуйте Нью-Йорк с Marvel’s Spider-Man 2 на PS5. Управляйте Питером Паркером и Майлзом Моралесом, сражайтесь с новыми врагами и раскрывайте тайны города. Купите сейчас и испытайте улучшенную графику и новые возможности!",
          "keywords": "Marvel’s Spider-Man 2, PS5, экшн, Человек-Паук, Нью-Йорк, Питер Паркер, Майлз Моралес",
          "robots": "index, follow",
          "og:title": "Marvel’s Spider-Man 2 - Приключение Человек-Паук на PS5",
          "og:description": "Погружайтесь в новое приключение с Marvel’s Spider-Man 2. Управляйте Питером Паркером и Майлзом Моралесом на PS5 и исследуйте Нью-Йорк в улучшенной графике.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 14,
        "info": {
          "name": "The Callisto Protocol",
          "price": {
            "ru": 5500,
            "kg": 4400
          },
          "description": "Погружайтесь в мир ужаса и выживания в игре The Callisto Protocol. Исследуйте заброшенную космическую тюрьму на спутнике Юпитера и сражайтесь с ужасающими существами в атмосфере постоянного страха и напряжения. Игра предлагает захватывающий сюжет, отличную графику и динамичную механику боя, которые заставят вас переживать каждое мгновение.",
          "image": "/img/catalog/ps5/the-callisto-protocol.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": "/img/catalog/banners/kalisto-protocol.webp"
        },
        "params": {
          "stock": 1,
          "sale": 45,
          "inTrash": false,
          "type": "игра",
          "genre": "ужасы",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "The Callisto Protocol - Ужасное Приключение на PS5",
          "description": "Погружайтесь в ужас и выживание в игре The Callisto Protocol на PS5. Исследуйте космическую тюрьму и сражайтесь с ужасными существами. Купите сейчас и испытайте атмосферу настоящего страха!",
          "keywords": "The Callisto Protocol, PS5, ужасы, космическая тюрьма, выживание, игра",
          "robots": "index, follow",
          "og:title": "The Callisto Protocol - Ужасное Приключение на PS5",
          "og:description": "Исследуйте заброшенную космическую тюрьму и сражайтесь с ужасными существами в игре The Callisto Protocol на PS5. Погружайтесь в мир ужаса и выживания.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 15,
        "info": {
          "name": "The Last of Us: Part II Remastered",
          "price": {
            "ru": 4600,
            "kg": 4600
          },
          "description": "Откройте для себя улучшенную версию культовой игры с The Last of Us: Part II Remastered. В этом издании вы сможете насладиться ещё более детализированной графикой, улучшенной анимацией и оптимизированным игровым процессом. Продолжайте путешествие с Элли через разрушенный мир, столкнитесь с новыми вызовами и раскройте глубокую и трогательную историю, которая затронет ваши эмоции.",
          "image": "/img/catalog/ps5/the-last-of-us-part-ii-remastered.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 5,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "The Last of Us: Part II Remastered - Усовершенствованное Приключение на PS5",
          "description": "Погружайтесь в улучшенную версию The Last of Us: Part II Remastered на PS5. Наслаждайтесь улучшенной графикой и оптимизированным игровым процессом, продолжая трогательное приключение с Элли.",
          "keywords": "The Last of Us Part II Remastered, PS5, улучшенная версия, приключения, Элли, графика",
          "robots": "index, follow",
          "og:title": "The Last of Us: Part II Remastered - Усовершенствованное Приключение на PS5",
          "og:description": "Откройте для себя усовершенствованную версию The Last of Us: Part II на PS5 с улучшенной графикой и оптимизированным игровым процессом. Продолжайте трогательное приключение с Элли.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 16,
        "info": {
          "name": "Stray",
          "price": {
            "ru": 3100,
            "kg": 3100
          },
          "description": "Исследуйте футуристический город в роли заблудшей кошки в игре Stray. Откройте для себя мир, полный тайн и приключений, взаимодействуйте с окружающей средой и решайте головоломки, чтобы найти путь домой. Игра предлагает уникальный взгляд на жизнь в мегаполисе с точки зрения кошки, обеспечивая захватывающий и эмоционально насыщенный опыт.",
          "image": "/img/catalog/ps5/stray.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Stray - Приключение Кошки в Футуристическом Городе на PS5",
          "description": "Исследуйте футуристический город в роли кошки в игре Stray на PS5. Решайте головоломки и открывайте тайны, чтобы найти путь домой. Купите сейчас и насладитесь уникальным приключением!",
          "keywords": "Stray, PS5, приключения, кошка, футуристический город, головоломки, игра",
          "robots": "index, follow",
          "og:title": "Stray - Приключение Кошки в Футуристическом Городе на PS5",
          "og:description": "Откройте для себя мир футуристического города через глаза кошки в игре Stray на PS5. Решайте головоломки и открывайте тайны, чтобы найти путь домой.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 17,
        "info": {
          "name": "Star Wars Jedi: Survivor",
          "price": {
            "ru": 3900,
            "kg": 4700
          },
          "description": "Погружайтесь в новое приключение в вселенной Star Wars с Star Wars Jedi: Survivor. Станьте частью захватывающего сюжета, исследуйте новые планеты и сражайтесь с могущественными врагами, используя свои навыки Джедая. Игра предлагает улучшенную графику, динамичные сражения и глубокое взаимодействие с миром, который будет держать вас в напряжении до самого конца.",
          "image": "/img/catalog/ps5/star-wars-jedi-survivor.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Star Wars Jedi: Survivor - Приключение Джедая на PS5",
          "description": "Погружайтесь в мир Star Wars с Star Wars Jedi: Survivor на PS5. Исследуйте новые планеты, сражайтесь с врагами и станьте настоящим Джедаєм. Купите сейчас и испытайте улучшенную графику и динамичные сражения!",
          "keywords": "Star Wars Jedi Survivor, PS5, экшн, Джедай, новые планеты, сражения, игра",
          "robots": "index, follow",
          "og:title": "Star Wars Jedi: Survivor - Приключение Джедая на PS5",
          "og:description": "Откройте для себя новое приключение в мире Star Wars с Star Wars Jedi: Survivor на PS5. Исследуйте планеты, сражайтесь с врагами и станьте настоящим Джедаєм.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 18,
        "info": {
          "name": "Returnal",
          "price": {
            "ru": 3600,
            "kg": 3600
          },
          "description": "Погружайтесь в мир неизведанных космических тайн с Returnal. В этой игре вы возьмете на себя роль астронавта, который застрял на чуждой планете, наполненной опасностями и загадками. Исследуйте случайно генерируемые локации, сражайтесь с враждебными существами и решайте головоломки, чтобы раскрыть тайны планеты и попытаться вернуться домой. Игра предлагает захватывающий экшен и глубокую историю.",
          "image": "/img/catalog/ps5/returnal.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2021,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Returnal - Космическое Приключение на PS5",
          "description": "Погружайтесь в неизведанный мир космических тайн с Returnal на PS5. Исследуйте опасные планеты, сражайтесь с врагами и раскрывайте тайны, чтобы вернуться домой. Купите сейчас и испытайте захватывающий экшен и глубокую историю!",
          "keywords": "Returnal, PS5, экшн, космическое приключение, чуждая планета, тайны, игра",
          "robots": "index, follow",
          "og:title": "Returnal - Космическое Приключение на PS5",
          "og:description": "Исследуйте опасные планеты и раскрывайте космические тайны с Returnal на PS5. Погружайтесь в захватывающий экшен и глубокую историю.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 19,
        "info": {
          "name": "Mortal Kombat 1",
          "price": {
            "ru": 3600,
            "kg": 3600
          },
          "description": "Возвращайтесь к истокам с Mortal Kombat 1, первой игрой в культовой серии файтингов. Испытайте в бою самых известных персонажей и насладитесь классическими фаталити и комбо. Сражайтесь с друзьями в захватывающих дуэлях или участвуйте в одиночных режимах, чтобы раскрыть историю и победить врагов. Mortal Kombat 1 предлагает ностальгический опыт с современными улучшениями.",
          "image": "/img/catalog/ps5/mortal-kombat-1.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "файтинг",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Mortal Kombat 1 - Классический Файтинг на PS5",
          "description": "Возвращайтесь к истокам с Mortal Kombat 1 на PS5. Испытайте классических персонажей, фаталити и комбо в современной версии первой игры серии. Купите сейчас и насладитесь ностальгическим опытом!",
          "keywords": "Mortal Kombat 1, PS5, файтинг, классическая игра, фаталити, комбо, игра",
          "robots": "index, follow",
          "og:title": "Mortal Kombat 1 - Классический Файтинг на PS5",
          "og:description": "Откройте для себя Mortal Kombat 1 на PS5. Сражайтесь с классическими персонажами и используйте фаталити в ностальгическом опыте первой игры серии.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 20,
        "info": {
          "name": "Lords of the Fallen",
          "price": {
            "ru": 5500,
            "kg": 5100
          },
          "description": "Погружайтесь в мрачный и опасный мир с Lords of the Fallen. В этой ролевой игре вам предстоит исследовать разрушенные королевства, сражаться с жуткими врагами и раскрывать тайны древних тёмных сил. Игра предлагает глубокую кастомизацию персонажа, сложные сражения и насыщенный сюжет, который удержит вас в напряжении до самого конца.",
          "image": "/img/catalog/ps5/lords-of-the-fallen.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 7,
          "inTrash": false,
          "type": "игра",
          "genre": "рпг",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Lords of the Fallen - Мрачная Ролевая Игра на PS5",
          "description": "Погружайтесь в мрачный мир с Lords of the Fallen на PS5. Исследуйте разрушенные королевства, сражайтесь с врагами и раскрывайте древние тайны. Купите сейчас и насладитесь сложными сражениями и насыщенным сюжетом!",
          "keywords": "Lords of the Fallen, PS5, рпг, мрачный мир, сражения, древние тайны, игра",
          "robots": "index, follow",
          "og:title": "Lords of the Fallen - Мрачная Ролевая Игра на PS5",
          "og:description": "Исследуйте разрушенные королевства и сражайтесь с жуткими врагами в Lords of the Fallen на PS5. Погружайтесь в мрачный мир и раскрывайте древние тайны.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 21,
        "info": {
          "name": "Lies of P",
          "price": {
            "ru": 5000,
            "kg": 5000
          },
          "description": "Погружайтесь в мрачный и загадочный мир Lies of P, основанный на классической сказке о Пиноккио. В этой игре вам предстоит исследовать мрачный город, полный опасностей и тайн. Используйте уникальные способности и оружие, чтобы преодолеть врагов и раскрыть правду о своем происхождении. Игра сочетает в себе элементы ролевого экшена и увлекательный сюжет с множеством неожиданных поворотов.",
          "image": "/img/catalog/ps5/lies-of-p.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "рпг",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Lies of P - Мрачный Ролевой Экшн на PS5",
          "description": "Откройте для себя Lies of P на PS5, мрачную ролевую игру, основанную на сказке о Пиноккио. Исследуйте опасный город, сражайтесь с врагами и раскрывайте свою судьбу. Купите сейчас и погружайтесь в захватывающий сюжет!",
          "keywords": "Lies of P, PS5, рпг, мрачный мир, Пиноккио, экшн, игра",
          "robots": "index, follow",
          "og:title": "Lies of P - Мрачный Ролевой Экшн на PS5",
          "og:description": "Исследуйте мрачный мир Lies of P на PS5, основанный на сказке о Пиноккио. Сражайтесь с врагами, раскрывайте тайны и погружайтесь в захватывающий сюжет.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 22,
        "info": {
          "name": "Kena: Bridge of Spirits",
          "price": {
            "ru": 3100,
            "kg": 4100
          },
          "description": "Погружайтесь в волшебный мир Kena: Bridge of Spirits, приключенческой игры с элементами ролевого экшена. В этой игре вы станете проводником душ, исследуете живописные локации, решаете головоломки и сражаетесь с врагами, используя магические способности и силу духов. Игра предлагает потрясающую графику и захватывающий сюжет, погружающий вас в мир волшебства и тайн.",
          "image": "/img/catalog/ps5/kena-bridge-of-spirits.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "приключения",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2021,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Kena: Bridge of Spirits - Волшебное Приключение на PS5",
          "description": "Исследуйте волшебный мир Kena: Bridge of Spirits на PS5. Погружайтесь в приключение, решайте головоломки и сражайтесь с врагами, используя магические способности и силу духов. Купите сейчас и насладитесь потрясающей графикой и захватывающим сюжетом!",
          "keywords": "Kena: Bridge of Spirits, PS5, приключения, магия, духи, головоломки, игра",
          "robots": "index, follow",
          "og:title": "Kena: Bridge of Spirits - Волшебное Приключение на PS5",
          "og:description": "Откройте для себя волшебный мир Kena: Bridge of Spirits на PS5. Решайте головоломки, сражайтесь с врагами и погружайтесь в захватывающее приключение с магическими способностями.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 23,
        "info": {
          "name": "Horizon: Forbidden West",
          "price": {
            "ru": 5500,
            "kg": 5500
          },
          "description": "Продолжайте эпическое приключение в Horizon: Forbidden West. В этой игре вы снова возьмете на себя роль Алиой, исследуя живописные и опасные земли Западного Побережья. Сражайтесь с мощными механическими существами, исследуйте захватывающие локации и раскрывайте тайны древних технологий. Игра предлагает потрясающую графику, богатый мир и увлекательный сюжет, который продолжает историю первой части.",
          "image": "/img/catalog/ps5/horizon-forbidden-west.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Horizon: Forbidden West - Эпическое Приключение на PS5",
          "description": "Исследуйте новые земли и сражайтесь с мощными механическими существами в Horizon: Forbidden West на PS5. Продолжайте приключения Алиой и раскрывайте тайны древних технологий. Купите сейчас и погружайтесь в захватывающий мир!",
          "keywords": "Horizon Forbidden West, PS5, экшн, приключения, механические существа, древние технологии, игра",
          "robots": "index, follow",
          "og:title": "Horizon: Forbidden West - Эпическое Приключение на PS5",
          "og:description": "Продолжайте эпическое приключение в Horizon: Forbidden West на PS5. Исследуйте живописные земли, сражайтесь с механическими существами и раскрывайте тайны древних технологий.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 24,
        "info": {
          "name": "Hogwarts Legacy",
          "price": {
            "ru": 3600,
            "kg": 3600
          },
          "description": "Погружайтесь в магический мир Hogwarts Legacy, ролевой игры, действие которой происходит в волшебном мире Гарри Поттера. Создайте собственного персонажа, обучайтесь магии, исследуйте знаменитую школу волшебства и её окрестности, а также принимайте участие в захватывающих квестах и сражениях. Игра предлагает уникальный опыт в мире Гарри Поттера, наполненный магией, приключениями и тайнами.",
          "image": "/img/catalog/ps5/hogwarts-legacy.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "рпг",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Hogwarts Legacy - Магическое Приключение на PS5",
          "description": "Погружайтесь в мир магии с Hogwarts Legacy на PS5. Создайте собственного персонажа, обучайтесь магии, исследуйте школу волшебства и её окрестности. Купите сейчас и насладитесь уникальным опытом в волшебном мире Гарри Поттера!",
          "keywords": "Hogwarts Legacy, PS5, рпг, Гарри Поттер, магия, школа волшебства, приключения",
          "robots": "index, follow",
          "og:title": "Hogwarts Legacy - Магическое Приключение на PS5",
          "og:description": "Откройте для себя мир магии в Hogwarts Legacy на PS5. Создайте персонажа, обучайтесь магии и исследуйте знаменитую школу волшебства. Погружайтесь в уникальное приключение в волшебном мире Гарри Поттера.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 25,
        "info": {
          "name": "Forspoken",
          "price": {
            "ru": 3600,
            "kg": 4600
          },
          "description": "Погружайтесь в фантастический мир Forspoken, ролевой игры с открытым миром, где вам предстоит играть за Фрей, современную женщину, которая оказывается в магическом мире. Используйте уникальные магические способности, исследуйте огромные локации и сражайтесь с разнообразными врагами, раскрывая тайны этого удивительного мира. Игра предлагает впечатляющую графику и захватывающий сюжет, наполненный магией и приключениями.",
          "image": "/img/catalog/ps5/forspoken.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 30,
          "inTrash": false,
          "type": "игра",
          "genre": "рпг",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Forspoken - Магическое Приключение на PS5",
          "description": "Погружайтесь в магический мир Forspoken на PS5. Играйте за Фрей, исследуйте огромные локации, используйте уникальные магические способности и раскрывайте тайны фантастического мира. Купите сейчас и насладитесь захватывающим сюжетом и впечатляющей графикой!",
          "keywords": "Forspoken, PS5, рпг, магический мир, магия, приключения, открытый мир",
          "robots": "index, follow",
          "og:title": "Forspoken - Магическое Приключение на PS5",
          "og:description": "Откройте для себя магический мир Forspoken на PS5. Исследуйте огромные локации, используйте магические способности и погружайтесь в захватывающий сюжет. Купите сейчас и насладитесь уникальным игровым опытом!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 26,
        "info": {
          "name": "Ghostrunner 2",
          "price": {
            "ru": 3500,
            "kg": 4000
          },
          "description": "Погружайтесь в постапокалиптический мир Ghostrunner 2, экшен-игры с видом от первого лица, где вы снова возьмете на себя роль кибернетического ниндзя. Используйте смертоносные комбинации акробатических приемов и высокотехнологичного оружия, чтобы преодолевать врагов и препятствия в высокоскоростных боях. Игра предлагает улучшенную графику, динамичные уровни и захватывающий сюжет, продолжая историю оригинала.",
          "image": "/img/catalog/ps5/ghostrunner-2.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "англиский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Ghostrunner 2 - Экшен-Приключение на PS5",
          "description": "Погружайтесь в мир Ghostrunner 2 на PS5. Используйте акробатические приемы и высокотехнологичное оружие, чтобы преодолевать врагов в высокоскоростных боях. Купите сейчас и насладитесь улучшенной графикой и динамичными уровнями!",
          "keywords": "Ghostrunner 2, PS5, экшн, кибернетический ниндзя, высокоскоростные бои, постапокалипсис, игра",
          "robots": "index, follow",
          "og:title": "Ghostrunner 2 - Экшен-Приключение на PS5",
          "og:description": "Откройте для себя Ghostrunner 2 на PS5. Сражайтесь с врагами, используйте акробатические приемы и высокотехнологичное оружие в динамичных боях. Погружайтесь в постапокалиптический мир с улучшенной графикой и захватывающим сюжетом.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 27,
        "info": {
          "name": "God of War Ragnarok",
          "price": {
            "ru": 4400,
            "kg": 4400
          },
          "description": "Продолжайте эпическое приключение в God of War Ragnarok, продолжении легендарной серии игр. В этой игре вы снова возьмете на себя роль Кратоса, который должен справиться с новыми угрозами и решить важные задачи в мифологическом мире. Исследуйте величественные локации, сражайтесь с мифическими существами и взаимодействуйте с богатым сюжетом. Игра предлагает потрясающую графику, захватывающие боевые сцены и глубокую проработку персонажей.",
          "image": "/img/catalog/ps5/god-of-war-ragnarok.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "God of War Ragnarok - Эпическое Приключение на PS5",
          "description": "Погружайтесь в эпическое приключение God of War Ragnarok на PS5. Сражайтесь с мифическими существами, исследуйте величественные локации и погружайтесь в богатый сюжет. Купите сейчас и насладитесь потрясающей графикой и захватывающими боями!",
          "keywords": "God of War Ragnarok, PS5, экшн, мифология, Кратос, приключения, графика",
          "robots": "index, follow",
          "og:title": "God of War Ragnarok - Эпическое Приключение на PS5",
          "og:description": "Откройте для себя God of War Ragnarok на PS5. Сражайтесь с мифическими существами, исследуйте новые локации и погружайтесь в захватывающий сюжет с потрясающей графикой и боевыми сценами.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 28,
        "info": {
          "name": "HITMAN World of Assassination",
          "price": {
            "ru": 4000,
            "kg": 3200
          },
          "description": "Погружайтесь в мир шпионских интриг и скрытных убийств с HITMAN World of Assassination. В этой игре вы вновь возьмете на себя роль агента 47, выполняя сложные контракты по всему миру. Используйте множество методов для устранения целей, от скрытного проникновения до прямых столкновений. Игра предлагает обширные и детализированные локации, множество вариантов прохождения и глубокий сюжет, полный тайн и неожиданных поворотов.",
          "image": "/img/catalog/ps5/hitman-world-of-assassination.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 5,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "HITMAN World of Assassination - Мир Убийств на PS5",
          "description": "Погружайтесь в мир шпионских интриг с HITMAN World of Assassination на PS5. Выполняйте контракты, используйте различные методы устранения целей и наслаждайтесь обширными локациями и глубоким сюжетом. Купите сейчас и раскройте тайны этого захватывающего мира!",
          "keywords": "HITMAN World of Assassination, PS5, экшн, шпионские интриги, агент 47, контракты, сюжет",
          "robots": "index, follow",
          "og:title": "HITMAN World of Assassination - Мир Убийств на PS5",
          "og:description": "Откройте для себя мир шпионских интриг с HITMAN World of Assassination на PS5. Выполняйте сложные контракты, используйте разнообразные методы устранения целей и погружайтесь в захватывающий сюжет.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 29,
        "info": {
          "name": "Pacific Drive Deluxe Edition",
          "price": {
            "ru": 3900,
            "kg": 3900
          },
          "description": "Погружайтесь в захватывающее приключение с Pacific Drive Deluxe Edition. В этой игре вы исследуете постапокалиптический мир, управляя модифицированным транспортным средством. Преодолевайте суровые условия, собирайте ресурсы и решайте головоломки, чтобы выжить в этом опасном мире. Deluxe Edition включает в себя эксклюзивные бонусы, такие как уникальные автомобили, дополнительные миссии и специальные предметы для вашего путешествия.",
          "image": "/img/catalog/ps5/pacific-drive-deluxe-edition.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": '/img/catalog/banners/pacific-drive.webp'
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Pacific Drive Deluxe Edition - Постапокалиптическое Приключение на PS5",
          "description": "Откройте для себя Pacific Drive Deluxe Edition на PS5. Управляйте модифицированным транспортным средством в постапокалиптическом мире, преодолевайте суровые условия и решайте головоломки. Deluxe Edition включает эксклюзивные бонусы и уникальные предметы. Купите сейчас и погружайтесь в захватывающее приключение!",
          "keywords": "Pacific Drive Deluxe Edition, PS5, экшн, постапокалипсис, модифицированные автомобили, приключения, эксклюзивные бонусы",
          "robots": "index, follow",
          "og:title": "Pacific Drive Deluxe Edition - Постапокалиптическое Приключение на PS5",
          "og:description": "Погружайтесь в постапокалиптический мир с Pacific Drive Deluxe Edition на PS5. Управляйте модифицированными автомобилями, собирайте ресурсы и решайте головоломки. Deluxe Edition предлагает эксклюзивные бонусы и уникальные предметы.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 30,
        "info": {
          "name": "Resident Evil 4: Remake",
          "price": {
            "ru": 3400,
            "kg": 3400
          },
          "description": "Погружайтесь в обновленную версию классического хоррора с Resident Evil 4: Remake. В этой переработанной версии вы снова окажетесь в роли Леона Кеннеди, который отправляется на поиски похищенной дочери президента. Обновленная графика, улучшенный геймплей и переработанные элементы сюжета делают эту версию еще более захватывающей и напряженной. Приготовьтесь к новой встрече с классическими врагами и испытаниям в улучшенной атмосфере ужаса.",
          "image": "/img/catalog/ps5/resident-evil-4-remake.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "хоррор",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Resident Evil 4: Remake - Обновленный Хоррор на PS5",
          "description": "Откройте для себя обновленную версию классического хоррора с Resident Evil 4: Remake на PS5. Возьмите на себя роль Леона Кеннеди и погружайтесь в мир ужаса с улучшенной графикой и переработанным геймплеем. Купите сейчас и насладитесь новым уровнем страха и напряжения!",
          "keywords": "Resident Evil 4: Remake, PS5, хоррор, Леон Кеннеди, обновленная графика, геймплей, классический хоррор",
          "robots": "index, follow",
          "og:title": "Resident Evil 4: Remake - Обновленный Хоррор на PS5",
          "og:description": "Погружайтесь в обновленную версию Resident Evil 4 на PS5. Возьмите на себя роль Леона Кеннеди и испытайте улучшенную графику и геймплей в мире ужаса. Купите сейчас и окунитесь в новое приключение с классическим страхом.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 31,
        "info": {
          "name": "Resident Evil 4: Gold Edition",
          "price": {
            "ru": 4500,
            "kg": 4500
          },
          "description": "Погружайтесь в мир ужаса с Resident Evil 4: Gold Edition, расширенной версией обновленного классического хоррора. В этой версии вы найдете все улучшения и контент оригинального ремейка, а также дополнительные эксклюзивные материалы, такие как новые миссии, оружие и костюмы. Воспользуйтесь всеми бонусами и уникальными элементами Gold Edition для еще более насыщенного и увлекательного игрового опыта в роли Леона Кеннеди.",
          "image": "/img/catalog/ps5/resident-evil-4-gold-edition.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "хоррор",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Resident Evil 4: Gold Edition - Расширенное Приключение на PS5",
          "description": "Откройте для себя Resident Evil 4: Gold Edition на PS5. Получите все улучшения оригинального ремейка и насладитесь эксклюзивными бонусами, такими как новые миссии, оружие и костюмы. Купите сейчас и погружайтесь в обновленный мир ужаса с уникальными элементами Gold Edition!",
          "keywords": "Resident Evil 4: Gold Edition, PS5, хоррор, эксклюзивные бонусы, новые миссии, оружие, костюмы",
          "robots": "index, follow",
          "og:title": "Resident Evil 4: Gold Edition - Расширенное Приключение на PS5",
          "og:description": "Погружайтесь в Resident Evil 4: Gold Edition на PS5. Наслаждайтесь всеми улучшениями ремейка и эксклюзивными бонусами, включая новые миссии, оружие и костюмы. Купите сейчас и испытайте расширенный мир ужаса.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 32,
        "info": {
          "name": "Ratchet & Clank: Сквозь миры",
          "price": {
            "ru": 3700,
            "kg": 3400
          },
          "description": "Погружайтесь в мир приключений с Ratchet & Clank: Rift Apart. В этой захватывающей игре вам предстоит путешествовать между различными мирами и реальностями, используя уникальные способности и оружие. Вас ждет динамичный геймплей, яркая графика и увлекательный сюжет, где Ratchet и Clank сталкиваются с новыми врагами и открывают для себя необычные миры. Наслаждайтесь непревзойденной экшен-графикой и новыми механиками в уникальном игровом опыте.",
          "image": "/img/catalog/ps5/ratchet-clank-rift-apart.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 7,
          "year_of_release": 2021,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Ratchet & Clank: Rift Apart - Приключения на PS5",
          "description": "Погружайтесь в захватывающий мир Ratchet & Clank: Rift Apart на PS5. Путешествуйте между мирами, используя уникальные способности и оружие. Наслаждайтесь яркой графикой и увлекательным сюжетом в этой динамичной экшен-игре. Купите сейчас и откройте для себя новые приключения!",
          "keywords": "Ratchet & Clank: Rift Apart, PS5, экшн, приключения, межмировые путешествия, графика, сюжет",
          "robots": "index, follow",
          "og:title": "Ratchet & Clank: Rift Apart - Приключения на PS5",
          "og:description": "Исследуйте мир Ratchet & Clank: Rift Apart на PS5. Путешествуйте между мирами, используя уникальные способности и оружие, и наслаждайтесь яркой графикой и увлекательным сюжетом. Купите сейчас и начните новые приключения!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 33,
        "info": {
          "name": "Resident Evil Village: Gold Edition",
          "price": {
            "ru": 3600,
            "kg": 3600
          },
          "description": "Погружайтесь в обновленный ужас с Resident Evil Village: Gold Edition. Эта расширенная версия включает в себя все оригинальное содержимое, а также новые миссии, персонажи и уникальные бонусы. Исследуйте зловещие локации, сражайтесь с новыми врагами и раскройте дополнительные сюжетные линии. Gold Edition предлагает улучшенные графические элементы и новые возможности для еще более захватывающего игрового опыта в мире Resident Evil.",
          "image": "/img/catalog/ps5/resident-evil-village-gold-edition.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "хоррор",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Resident Evil Village: Gold Edition - Расширенный Ужас на PS5",
          "description": "Откройте для себя Resident Evil Village: Gold Edition на PS5. Наслаждайтесь новым контентом, включая дополнительные миссии, персонажей и уникальные бонусы. Погружайтесь в обновленный мир ужаса с улучшенной графикой и новыми возможностями. Купите сейчас и испытайте расширенное приключение!",
          "keywords": "Resident Evil Village: Gold Edition, PS5, хоррор, новые миссии, персонажи, бонусы, улучшенная графика",
          "robots": "index, follow",
          "og:title": "Resident Evil Village: Gold Edition - Расширенный Ужас на PS5",
          "og:description": "Погружайтесь в расширенную версию Resident Evil Village на PS5. Новые миссии, персонажи и уникальные бонусы добавляют к ужасу обновленную графику и возможности. Купите сейчас и откройте для себя новые элементы этого захватывающего мира!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 34,
        "info": {
          "name": "Diablo 4",
          "price": {
            "ru": 4000,
            "kg": 4000
          },
          "description": "Погружайтесь в мрачный мир Диабло 4, где темные силы вновь угрожают человечеству. Исследуйте обширные локации, сражайтесь с ужасными монстрами и собирайте уникальные предметы в этом захватывающем экшн-RPG. Игра предлагает улучшенную графику, глубокий сюжет и множество возможностей для настройки персонажа. Присоединяйтесь к битве против зла и раскройте тайны этого мрачного мира.",
          "image": "/img/catalog/ps5/diablo-4.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн-RPG",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Diablo 4 - Эпическое Приключение на PS5",
          "description": "Откройте для себя Diablo 4 на PS5. Исследуйте мрачный мир, сражайтесь с монстрами и собирайте уникальные предметы в этом экшн-RPG. Наслаждайтесь улучшенной графикой, глубоким сюжетом и множеством возможностей для настройки персонажа. Купите сейчас и погружайтесь в эпическое приключение!",
          "keywords": "Diablo 4, PS5, экшн-RPG, мрачный мир, монстры, уникальные предметы, глубокий сюжет",
          "robots": "index, follow",
          "og:title": "Diablo 4 - Эпическое Приключение на PS5",
          "og:description": "Погружайтесь в мрачный мир Diablo 4 на PS5. Сражайтесь с ужасными монстрами, собирайте уникальные предметы и исследуйте обширные локации. Наслаждайтесь улучшенной графикой и глубоким сюжетом. Купите сейчас и начните эпическое приключение!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 35,
        "info": {
          "name": "Alone in the Dark",
          "price": {
            "ru": 4000,
            "kg": 4700
          },
          "description": "Погружайтесь в атмосферу ужаса с Alone in the Dark. Эта игра возвращает вас в мрачный мир с глубоким сюжетом, завораживающими локациями и захватывающим геймплеем. Исследуйте таинственные места, решайте головоломки и сражайтесь с темными силами, чтобы раскрыть страшные тайны. Alone in the Dark предлагает обновленную графику и новые элементы, которые сделают ваш опыт еще более захватывающим.",
          "image": "/img/catalog/ps5/alone-in-the-dark.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "хоррор",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Alone in the Dark - Мрачный Хоррор на PS5",
          "description": "Откройте для себя атмосферу ужаса с Alone in the Dark на PS5. Исследуйте таинственные места, решайте головоломки и сражайтесь с темными силами. Наслаждайтесь обновленной графикой и новым контентом для еще более захватывающего игрового опыта. Купите сейчас и погружайтесь в мир ужаса!",
          "keywords": "Alone in the Dark, PS5, хоррор, мрак, головоломки, темные силы, обновленная графика",
          "robots": "index, follow",
          "og:title": "Alone in the Dark - Мрачный Хоррор на PS5",
          "og:description": "Погружайтесь в мир ужаса с Alone in the Dark на PS5. Исследуйте таинственные места, решайте головоломки и сражайтесь с темными силами. Наслаждайтесь обновленной графикой и новым контентом. Купите сейчас и погружайтесь в мрак!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 36,
        "info": {
          "name": "Dragon’s Dogma 2",
          "price": {
            "ru": 5400,
            "kg": 5400
          },
          "description": "Погружайтесь в захватывающий мир Dragon’s Dogma 2, продолжения любимой RPG, которое предлагает новые уровни приключений и эпические битвы. Исследуйте обширные локации, сражайтесь с мощными монстрами и управляйте своими союзниками в стратегическом бою. С обновленной графикой, глубокой системой квестов и улучшенным геймплеем, Dragon’s Dogma 2 предлагает богатый и увлекательный опыт для фанатов и новичков.",
          "image": "/img/catalog/ps5/dragons-dogma-2.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": "/img/catalog/banners/dragon’s-dogma-2.webp"
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "RPG",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2024,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Dragon’s Dogma 2 - Эпическое RPG Приключение на PS5",
          "description": "Откройте для себя Dragon’s Dogma 2 на PS5. Погружайтесь в мир RPG с новыми приключениями, мощными монстрами и стратегическим боем. Наслаждайтесь обновленной графикой и глубокой системой квестов. Купите сейчас и начните ваше эпическое приключение!",
          "keywords": "Dragon’s Dogma 2, PS5, RPG, эпические приключения, стратегический бой, обновленная графика",
          "robots": "index, follow",
          "og:title": "Dragon’s Dogma 2 - Эпическое RPG Приключение на PS5",
          "og:description": "Погружайтесь в мир Dragon’s Dogma 2 на PS5. Откройте для себя новые приключения, сражайтесь с мощными монстрами и управляйте своими союзниками. Наслаждайтесь обновленной графикой и глубокой системой квестов. Купите сейчас и начните ваше эпическое RPG-приключение!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 37,
        "info": {
          "name": "Far Cry 6",
          "price": {
            "ru": 2400,
            "kg": 2900
          },
          "description": "Добро пожаловать в мир Far Cry 6, где вас ждет эпическое приключение на тропическом острове. В этой игре вы сыграете за революционера, борющегося против диктатора, захватившего власть в вымышленной стране. Открытый мир Far Cry 6 предлагает разнообразные задания, захватывающие сражения и возможность настраивать оружие и технику. Исследуйте обширные локации, вступайте в бой с врагами и используйте различные стратегии для достижения цели.",
          "image": "/img/catalog/ps5/far-cry-6.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2021,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Far Cry 6 - Эпическое Приключение на PS5",
          "description": "Откройте для себя Far Cry 6 на PS5. Погружайтесь в мир тропического острова, боритесь с диктатором и исследуйте открытый мир. Настраивайте оружие, используйте различные стратегии и наслаждайтесь захватывающими сражениями. Купите сейчас и начните ваше эпическое приключение!",
          "keywords": "Far Cry 6, PS5, экшн, тропический остров, революция, открытый мир, настройка оружия",
          "robots": "index, follow",
          "og:title": "Far Cry 6 - Эпическое Приключение на PS5",
          "og:description": "Погружайтесь в мир Far Cry 6 на PS5. Боритесь с диктатором, исследуйте тропический остров и наслаждайтесь открытым миром с возможностью настройки оружия и техники. Купите сейчас и начните ваше эпическое приключение!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 39,
        "info": {
          "name": "Star Wars: The Skywalker Saga",
          "price": {
            "ru": 2500,
            "kg": 4400
          },
          "description": "Погружайтесь в захватывающий мир Star Wars: The Skywalker Saga и переживайте эпические события всех девяти фильмов саги. Эта игра предлагает вам уникальную возможность исследовать обширные планеты, управлять знаменитыми космическими кораблями и участвовать в грандиозных сражениях. Воссоздайте ключевые моменты истории Звездных Войн, от формирования Республиканской армии до противостояния с Империей. Игра предлагает улучшенную графику, богатый геймплей и возможность играть в одиночку или в кооперативе.",
          "image": "/img/catalog/ps5/star-wars-the-skywalker-saga.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "англиский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Star Wars: The Skywalker Saga - Эпические События на PS5",
          "description": "Погружайтесь в Star Wars: The Skywalker Saga на PS5. Переживайте события всех девяти фильмов Звездных Войн, исследуйте планеты, управляйте космическими кораблями и участвуйте в сражениях. Наслаждайтесь улучшенной графикой и многопользовательским режимом. Купите сейчас и присоединяйтесь к саге!",
          "keywords": "Star Wars: The Skywalker Saga, PS5, Звездные Войны, эпические события, космические корабли, сражения",
          "robots": "index, follow",
          "og:title": "Star Wars: The Skywalker Saga - Эпические События на PS5",
          "og:description": "Откройте для себя Star Wars: The Skywalker Saga на PS5. Переживайте события девяти фильмов Звездных Войн, исследуйте планеты, управляйте космическими кораблями и участвуйте в грандиозных сражениях. Наслаждайтесь улучшенной графикой и многопользовательским режимом. Купите сейчас и погружайтесь в сагу!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 40,
        "info": {
          "name": "Metro: Exodus Complete Edition",
          "price": {
            "ru": 2400,
            "kg": 2800
          },
          "description": "Погружайтесь в мир Metro: Exodus Complete Edition, который предлагает полное погружение в постапокалиптический мир метро. В этом издании вы получите доступ ко всем DLC и обновлениям, включая новые миссии, оружие и улучшения. Исследуйте огромные открытые локации, сражайтесь с мутантами и враждебными группировками, и переживайте эпические события в уникальной атмосфере выживания. Наслаждайтесь улучшенной графикой и обновленным контентом, который расширяет уже захватывающий опыт игры.",
          "image": "/img/catalog/ps5/metro-exodus-complete-edition.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "шутер от первого лица",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2022,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Metro: Exodus Complete Edition - Полное Приключение на PS5",
          "description": "Откройте для себя Metro: Exodus Complete Edition на PS5. Погружайтесь в постапокалиптический мир метро с доступом ко всем DLC и обновлениям. Исследуйте открытые локации, сражайтесь с мутантами и враждебными группировками. Наслаждайтесь улучшенной графикой и расширенным контентом. Купите сейчас и погружайтесь в полное приключение!",
          "keywords": "Metro: Exodus Complete Edition, PS5, постапокалипсис, DLC, открытые локации, улучшенная графика",
          "robots": "index, follow",
          "og:title": "Metro: Exodus Complete Edition - Полное Приключение на PS5",
          "og:description": "Погружайтесь в Metro: Exodus Complete Edition на PS5. Откройте все DLC и обновления, исследуйте открытые локации, сражайтесь с мутантами и враждебными группировками. Наслаждайтесь улучшенной графикой и расширенным контентом. Купите сейчас и погружайтесь в полное приключение!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 41,
        "info": {
          "name": "NHL 23",
          "price": {
            "ru": 5000,
            "kg": 6200
          },
          "description": "Испытайте адреналин ледового хоккея с NHL 23, самой реалистичной и захватывающей хоккейной симуляцией. Играйте за свои любимые команды и игроков, участвуйте в чемпионатах и создавайте собственные команды. NHL 23 предлагает улучшенную графику, обновленные составы, реалистичную физику и динамичный геймплей. Вступайте в захватывающие матчи, испытайте новые режимы игры и наслаждайтесь хоккейными баталиями на льду.",
          "image": "/img/catalog/ps5/nhl-23.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "спортивная симуляция",
          "platform": "ps5",
          "interface_language": "английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 6,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "NHL 23 - Ледовые Баталии на PS5",
          "description": "Откройте для себя NHL 23 на PS5. Испытайте адреналин ледового хоккея с обновленными составами, реалистичной физикой и динамичным геймплеем. Играйте за любимые команды, участвуйте в чемпионатах и наслаждайтесь хоккейными баталиями на льду. Купите сейчас и погрузитесь в мир хоккея!",
          "keywords": "NHL 23, PS5, хоккей, спортивная симуляция, ледовые баталии, чемпионаты",
          "robots": "index, follow",
          "og:title": "NHL 23 - Ледовые Баталии на PS5",
          "og:description": "Испытайте адреналин ледового хоккея с NHL 23 на PS5. Обновленные составы, реалистичная физика и динамичный геймплей ждут вас. Играйте за любимые команды и наслаждайтесь хоккейными баталиями на льду. Купите сейчас и погрузитесь в мир хоккея!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 42,
        "info": {
          "name": "SnowRunner",
          "price": {
            "ru": 2400,
            "kg": 3500
          },
          "description": "Погрузитесь в экстремальные внедорожные приключения с SnowRunner. Эта игра предлагает вам управлять мощными внедорожниками и грузовиками, преодолевая суровые условия на различных локациях, включая грязь, снег и реки. Исследуйте открытые миры, выполняйте сложные задания и наслаждайтесь реалистичной физикой движения. SnowRunner предоставляет уникальный опыт вождения и возможность настраивать свои автомобили, чтобы справляться с любыми трудностями.",
          "image": "/img/catalog/ps5/snowrunner.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "симулятор",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2020,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "SnowRunner - Экстремальные Внедорожные Приключения на PS5",
          "description": "Откройте для себя SnowRunner на PS5. Управляйте мощными внедорожниками и грузовиками, преодолевайте суровые условия и выполняйте сложные задания. Наслаждайтесь реалистичной физикой движения и настройкой автомобилей. Купите сейчас и отправляйтесь в экстремальные внедорожные приключения!",
          "keywords": "SnowRunner, PS5, внедорожники, симулятор, экстремальные приключения, настройка автомобилей",
          "robots": "index, follow",
          "og:title": "SnowRunner - Экстремальные Внедорожные Приключения на PS5",
          "og:description": "Погрузитесь в экстремальные внедорожные приключения с SnowRunner на PS5. Управляйте мощными внедорожниками, преодолевайте суровые условия и выполняйте сложные задания. Наслаждайтесь реалистичной физикой движения и настройкой автомобилей. Купите сейчас и отправляйтесь в приключения!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 43,
        "info": {
          "name": "Watch Dogs: Legion",
          "price": {
            "ru": 2200,
            "kg": 2800
          },
          "description": "Погрузитесь в футуристический Лондон с Watch Dogs: Legion, где каждая личность имеет значение. Игра предлагает уникальную механику набора персонажей: вы можете вербовать любого жителя города и играть за него. Ведите борьбу против тоталитарного режима, используя хакерские навыки, дронов и различные гаджеты. Исследуйте открытый мир, выполняйте разнообразные миссии и наслаждайтесь захватывающим сюжетом, который реагирует на ваши действия. Watch Dogs: Legion предлагает динамичный геймплей и множество возможностей для кастомизации.",
          "image": "/img/catalog/ps5/watch-dogs-legion.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн-приключение",
          "platform": "ps5",
          "interface_language": "английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2020,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "Watch Dogs: Legion - Футуристический Лондон на PS5",
          "description": "Откройте для себя Watch Dogs: Legion на PS5. Ведите борьбу против тоталитарного режима, используя хакерские навыки, дронов и гаджеты. Исследуйте открытый мир, выполняйте миссии и наслаждайтесь динамичным геймплеем. Купите сейчас и погружайтесь в футуристический Лондон!",
          "keywords": "Watch Dogs: Legion, PS5, футуристический Лондон, хакерство, экшн-приключение, открытый мир",
          "robots": "index, follow",
          "og:title": "Watch Dogs: Legion - Футуристический Лондон на PS5",
          "og:description": "Погрузитесь в футуристический Лондон с Watch Dogs: Legion на PS5. Ведите борьбу против тоталитарного режима, используя хакерские навыки, дронов и гаджеты. Исследуйте открытый мир, выполняйте миссии и наслаждайтесь динамичным геймплеем. Купите сейчас и погружайтесь в игру!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 44,
        "info": {
          "name": "WWE 2K24",
          "price": {
            "ru": 4590,
            "kg": 4690
          },
          "description": "Погрузитесь в мир профессионального рестлинга с WWE 2K24. Эта игра предлагает вам реалистичные бои, обширный список бойцов и разнообразные режимы игры. Создавайте собственных рестлеров, участвуйте в эпических матчах и разрабатывайте стратегии для победы. WWE 2K24 включает улучшенную графику, анимации и звуковое сопровождение, что делает каждое сражение ещё более захватывающим. Исследуйте карьерный режим, соревнуйтесь с друзьями в многопользовательских матчах и станьте легендой рестлинга.",
          "image": "/img/catalog/ps4/wwe-2k24.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "спортивная симуляция",
          "platform": "ps4",
          "interface_language": "английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "WWE 2K24 - Профессиональный Рестлинг на PS4",
          "description": "Откройте для себя WWE 2K24 на PS4. Участвуйте в реалистичных боях, создавайте собственных рестлеров и соревнуйтесь с друзьями в многопользовательских матчах. Игра включает улучшенную графику и звуковое сопровождение. Купите сейчас и станьте легендой рестлинга!",
          "keywords": "WWE 2K24, PS4, рестлинг, спортивная симуляция, многопользовательские матчи, карьера",
          "robots": "index, follow",
          "og:title": "WWE 2K24 - Профессиональный Рестлинг на PS4",
          "og:description": "Погрузитесь в мир профессионального рестлинга с WWE 2K24 на PS4. Участвуйте в реалистичных боях, создавайте собственных рестлеров и соревнуйтесь с друзьями в многопользовательских матчах. Улучшенная графика и звуковое сопровождение делают игру ещё более захватывающей. Купите сейчас и станьте легендой рестлинга!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 45,
        "info": {
          "name": "Street Fighter 6",
          "price": {
            "ru": 3800,
            "kg": 5200
          },
          "description": "Испытайте захватывающие боевые действия с Street Fighter 6. В этой игре вас ждут динамичные бои, разнообразные персонажи с уникальными способностями и захватывающие арены. Улучшенная графика и анимации делают каждое сражение ещё более впечатляющим. Street Fighter 6 предлагает множество режимов игры, включая одиночный режим, многопользовательские сражения и турниры. Создайте своего бойца, настраивайте его внешний вид и навыки, и сражайтесь за звание лучшего бойца мира.",
          "image": "/img/catalog/ps4/street-fighter-6.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "файтинг",
          "platform": "ps4",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Street Fighter 6 - Эпические Бои на PS4",
          "description": "Откройте для себя Street Fighter 6 на PS4. Участвуйте в динамичных боях с разнообразными персонажами, улучшенной графикой и анимациями. Играйте в одиночном режиме, многопользовательских сражениях и турнирах. Создайте своего бойца и станьте лучшим бойцом мира. Купите сейчас и начните свой путь к победе!",
          "keywords": "Street Fighter 6, PS4, файтинг, боевые игры, динамичные бои, многопользовательские сражения",
          "robots": "index, follow",
          "og:title": "Street Fighter 6 - Эпические Бои на PS4",
          "og:description": "Испытайте захватывающие боевые действия с Street Fighter 6 на PS4. Участвуйте в динамичных боях с разнообразными персонажами и улучшенной графикой. Играйте в одиночном режиме, многопользовательских сражениях и турнирах. Создайте своего бойца и станьте лучшим бойцом мира. Купите сейчас и начните свой путь к победе!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 46,
        "info": {
          "name": "Street Fighter 6",
          "price": {
            "ru": 4500,
            "kg": 5400
          },
          "description": "Испытайте захватывающие боевые действия с Street Fighter 6. В этой игре вас ждут динамичные бои, разнообразные персонажи с уникальными способностями и захватывающие арены. Улучшенная графика и анимации делают каждое сражение ещё более впечатляющим. Street Fighter 6 предлагает множество режимов игры, включая одиночный режим, многопользовательские сражения и турниры. Создайте своего бойца, настраивайте его внешний вид и навыки, и сражайтесь за звание лучшего бойца мира.",
          "image": "/img/catalog/ps5/street-fighter-6.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "файтинг",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 12,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Street Fighter 6 - Эпические Бои на PS5",
          "description": "Откройте для себя Street Fighter 6 на PS5. Участвуйте в динамичных боях с разнообразными персонажами, улучшенной графикой и анимациями. Играйте в одиночном режиме, многопользовательских сражениях и турнирах. Создайте своего бойца и станьте лучшим бойцом мира. Купите сейчас и начните свой путь к победе!",
          "keywords": "Street Fighter 6, PS5, файтинг, боевые игры, динамичные бои, многопользовательские сражения",
          "robots": "index, follow",
          "og:title": "Street Fighter 6 - Эпические Бои на PS5",
          "og:description": "Испытайте захватывающие боевые действия с Street Fighter 6 на PS5. Участвуйте в динамичных боях с разнообразными персонажами и улучшенной графикой. Играйте в одиночном режиме, многопользовательских сражениях и турнирах. Создайте своего бойца и станьте лучшим бойцом мира. Купите сейчас и начните свой путь к победе!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 47,
        "info": {
          "name": "Dead Island 2 Day One Edition",
          "price": {
            "ru": 4500,
            "kg": 4500
          },
          "description": "Испытайте напряженные боевые действия и выживание в постапокалиптическом мире с Dead Island 2 Day One Edition. Погрузитесь в жестокие сражения с ордами зомби, исследуйте зараженные города и развивайте своего персонажа, улучшая его способности и вооружение. Улучшенная графика и реалистичные эффекты создают непередаваемую атмосферу ужаса и опасности. Dead Island 2 предлагает как одиночные, так и многопользовательские режимы, где вы можете объединяться с друзьями для борьбы с зомби. Эта версия включает эксклюзивные бонусы и контент для первых покупателей.",
          "image": "/img/catalog/ps5/dead-island-2-day-one-edition.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 43,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн, хоррор",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Dead Island 2 Day One Edition - Выживание в Зомби Апокалипсисе на PS5",
          "description": "Откройте для себя Dead Island 2 Day One Edition на PS5. Погрузитесь в жестокие сражения с ордами зомби, исследуйте зараженные города и развивайте своего персонажа. Играйте в одиночном и многопользовательском режимах, наслаждаясь улучшенной графикой и реалистичными эффектами. Купите сейчас и получите эксклюзивные бонусы!",
          "keywords": "Dead Island 2, Day One Edition, PS5, экшн, хоррор, зомби, выживание, многопользовательские режимы",
          "robots": "index, follow",
          "og:title": "Dead Island 2 Day One Edition - Выживание в Зомби Апокалипсисе на PS5",
          "og:description": "Испытайте напряженные боевые действия и выживание в постапокалиптическом мире с Dead Island 2 Day One Edition на PS5. Погрузитесь в жестокие сражения с ордами зомби, исследуйте зараженные города и развивайте своего персонажа. Улучшенная графика и реалистичные эффекты создают непередаваемую атмосферу ужаса и опасности. Играйте в одиночном и многопользовательском режимах и получите эксклюзивные бонусы!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 48,
        "info": {
          "name": "Deathloop",
          "price": {
            "ru": 2800,
            "kg": 4100
          },
          "description": "Откройте для себя инновационный шутер от первого лица с уникальной механикой временных петель в игре Deathloop. Игрокам предстоит управлять наемником Колтом, застрявшим на таинственном острове, где каждый день повторяется заново. Используйте разнообразное оружие и способности, чтобы устранить восемь ключевых целей и разорвать петлю. Игра предлагает как одиночный режим, так и асимметричный мультиплеер, где другие игроки могут вторгаться в ваш мир как убийца Джулианна. Отличная графика, увлекательный геймплей и уникальная сюжетная линия делают Deathloop одной из самых ожидаемых игр на PS5.",
          "image": "/img/catalog/ps5/deathloop.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "шутер, приключения",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Deathloop - Уникальный Шутер на PS5",
          "description": "Погрузитесь в мир Deathloop на PS5. Управляйте наемником Колтом, застрявшим во временной петле, и используйте разнообразное оружие и способности, чтобы устранить восемь ключевых целей. Играйте в одиночном режиме или в асимметричном мультиплеере. Купите сейчас и испытайте уникальный игровой процесс!",
          "keywords": "Deathloop, PS5, шутер, временные петли, приключения, асимметричный мультиплеер",
          "robots": "index, follow",
          "og:title": "Deathloop - Уникальный Шутер на PS5",
          "og:description": "Откройте для себя инновационный шутер Deathloop на PS5. Управляйте наемником Колтом, застрявшим во временной петле, и используйте разнообразное оружие и способности, чтобы устранить восемь ключевых целей. Играйте в одиночном режиме или в асимметричном мультиплеере. Купите сейчас и испытайте уникальный игровой процесс!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 49,
        "info": {
          "name": "Final Fantasy XVI",
          "price": {
            "ru": 3500,
            "kg": 4800
          },
          "description": "Погрузитесь в эпическое приключение с Final Fantasy XVI, эксклюзивной для PS5. Исследуйте фантастический мир Валистеа, наполненный магией, загадочными существами и эпическими сражениями. Следуйте за главным героем Клайвом Росфилдом, который стремится защитить свою семью и страну от надвигающейся угрозы. Используйте мощные заклинания и оружие, чтобы сражаться с врагами и раскрывать тайны этого удивительного мира. Великолепная графика, захватывающий сюжет и динамичный геймплей делают Final Fantasy XVI обязательной игрой для всех поклонников жанра RPG.",
          "image": "/img/catalog/ps5/final-fantasy-xvi.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 10,
          "inTrash": false,
          "type": "игра",
          "genre": "ролевые игры",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": false,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Final Fantasy XVI - Эпическое Приключение на PS5",
          "description": "Исследуйте мир Валистеа в Final Fantasy XVI на PS5. Сражайтесь с врагами, используя мощные заклинания и оружие, и следуйте за захватывающим сюжетом. Играйте в одиночном режиме и наслаждайтесь великолепной графикой. Купите сейчас и погрузитесь в незабываемое приключение!",
          "keywords": "Final Fantasy XVI, PS5, RPG, ролевые игры, эпическое приключение, магия, фантастический мир",
          "robots": "index, follow",
          "og:title": "Final Fantasy XVI - Эпическое Приключение на PS5",
          "og:description": "Погрузитесь в эпическое приключение с Final Fantasy XVI на PS5. Исследуйте фантастический мир Валистеа, сражайтесь с врагами, используя мощные заклинания и оружие, и следуйте за захватывающим сюжетом. Купите сейчас и наслаждайтесь великолепной графикой и динамичным геймплеем!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 50,
        "info": {
          "name": "Gran Turismo 7",
          "price": {
            "ru": 5500,
            "kg": 3800
          },
          "description": "Откройте для себя Gran Turismo 7 на PS5 — новейшую версию легендарной гоночной симуляции. Наслаждайтесь невероятной графикой, реалистичным управлением и огромным выбором автомобилей. Gran Turismo 7 предлагает широкий спектр режимов, включая одиночные гонки, многопользовательские состязания и карьерный режим. Сохраняйте и улучшайте свои автомобили, исследуйте детализированные трассы и испытайте свои навыки в соревнованиях с игроками со всего мира. Великолепная визуализация и продвинутый физический движок делают каждую гонку по-настоящему увлекательной.",
          "image": "/img/catalog/ps5/gran-turismo-7.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 1,
          "inTrash": false,
          "type": "игра",
          "genre": "гоночные симуляторы",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 3,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Gran Turismo 7 - Гоночная Симуляция на PS5",
          "description": "Погрузитесь в мир гонок с Gran Turismo 7 на PS5. Наслаждайтесь реалистичным управлением, отличной графикой и широким выбором автомобилей. Играйте в одиночном и многопользовательском режимах, улучшайте свои машины и исследуйте детализированные трассы. Купите сейчас и испытайте себя в захватывающих гонках!",
          "keywords": "Gran Turismo 7, PS5, гоночные симуляторы, автомобили, гонки, реалистичное управление",
          "robots": "index, follow",
          "og:title": "Gran Turismo 7 - Гоночная Симуляция на PS5",
          "og:description": "Откройте для себя Gran Turismo 7 на PS5. Наслаждайтесь реалистичным управлением, отличной графикой и широким выбором автомобилей. Играйте в одиночном и многопользовательском режимах, улучшайте свои машины и исследуйте детализированные трассы. Купите сейчас и испытайте себя в захватывающих гонках!",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 51,
        "info": {
          "name": "Gotham Knights",
          "price": {
            "ru": 3500,
            "kg": 3500
          },
          "description": "Присоединяйтесь к команде героев в Gotham Knights на PS5. Исследуйте мрачный мир Готэма, играйте за Робина, Бэтгерл, Найтвинга или Красного Колпака и сражайтесь с угрозами, которые надвигаются на город после смерти Бэтмена. Каждый герой обладает уникальными способностями и стилем боя, что позволяет вам выбирать тактику и подход, соответствующие вашему стилю игры. Gotham Knights предлагает как одиночный, так и кооперативный режим, где вы можете объединяться с друзьями для выполнения миссий и борьбы с преступностью. Великолепная графика и интригующий сюжет делают эту игру обязательной для всех поклонников вселенной DC.",
          "image": "/img/catalog/ps5/gotham-knights.webp",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 1,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн, приключения",
          "platform": "ps5",
          "interface_language": "русский",
          "voice_language": "русский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 16,
          "year_of_release": 2023,
          "new_arrive": true,
          "media_type_electron": false
        },
        "seo": {
          "title": "Gotham Knights - Герои Готэма на PS5",
          "description": "Присоединяйтесь к героям в Gotham Knights на PS5. Играйте за Робина, Бэтгерл, Найтвинга или Красного Колпака, исследуйте Готэм и боритесь с преступностью в одиночном или кооперативном режиме. Великолепная графика и интригующий сюжет ждут вас в этой игре вселенной DC.",
          "keywords": "Gotham Knights, PS5, экшн, приключения, Бэтмен, Робин, Бэтгерл, Найтвинг, Красный Колпак",
          "robots": "index, follow",
          "og:title": "Gotham Knights - Герои Готэма на PS5",
          "og:description": "Исследуйте Готэм и присоединяйтесь к героям в Gotham Knights на PS5. Играйте за Робина, Бэтгерл, Найтвинга или Красного Колпака, и боритесь с преступностью в одиночном или кооперативном режиме. Великолепная графика и интригующий сюжет сделают вашу игру незабываемой.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 52,
        "info": {
          "name": "GTA 5",
          "price": {
            "ru": 1900,
            "kg": 2300
          },
          "description": "Добро пожаловать в Лос-Сантос - город, где деньги, власть и преступность переплетаются в захватывающем игровом мире. Управляйте тремя уникальными героями и исследуйте открытый мир, полный приключений и опасностей. GTA 5 на PS4 и PS5 подарит вам невероятный игровой опыт, сочетающий экшн, гонки и стратегию.",
          "image": "https://ir.ozone.ru/s3/multimedia-s/wc1000/6725713204.jpg",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps5",
          "interface_language": "русский, английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2013,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "GTA 5 - Экшн в Лос-Сантосе на PS4 и PS5",
          "description": "Погрузитесь в мир Лос-Сантоса, где власть и преступность пересекаются. Играй в GTA 5 на PS4 и PS5 и управляй тремя героями в захватывающем открытом мире.",
          "keywords": "GTA 5, PS4, PS5, Лос-Сантос, экшн, приключения, открытый мир, игра",
          "robots": "index, follow",
          "og:title": "GTA 5 - Экшн в Лос-Сантосе на PS4 и PS5",
          "og:description": "Исследуйте Лос-Сантос, играйте за трех уникальных персонажей и погрузитесь в мир криминала и адреналина в GTA 5 на PS4 и PS5.",
          "og:image": "https://example.com/image.jpg"
        }
      },
      {
        "id": 53,
        "info": {
          "name": "GTA 5",
          "price": {
            "ru": 2200,
            "kg": 2700
          },
          "description": "Добро пожаловать в Лос-Сантос - город, где деньги, власть и преступность переплетаются в захватывающем игровом мире. Управляйте тремя уникальными героями и исследуйте открытый мир, полный приключений и опасностей. GTA 5 на PS4 и PS5 подарит вам невероятный игровой опыт, сочетающий экшн, гонки и стратегию.",
          "image": "https://ir.ozone.ru/s3/multimedia-y/wc1000/6169062046.jpg",
          "youtube_video": ""
        },
        "banner": {
          "image": null
        },
        "params": {
          "stock": 1,
          "sale": 15,
          "inTrash": false,
          "type": "игра",
          "genre": "экшн",
          "platform": "ps4",
          "interface_language": "русский, английский",
          "voice_language": "английский",
          "single_mode": true,
          "multiplayer_mode": true,
          "vr_support": false,
          "age_restrictions": 18,
          "year_of_release": 2013,
          "new_arrive": false,
          "media_type_electron": false
        },
        "seo": {
          "title": "GTA 5 - Экшн в Лос-Сантосе на PS4 и PS5",
          "description": "Погрузитесь в мир Лос-Сантоса, где власть и преступность пересекаются. Играй в GTA 5 на PS4 и PS5 и управляй тремя героями в захватывающем открытом мире.",
          "keywords": "GTA 5, PS4, PS5, Лос-Сантос, экшн, приключения, открытый мир, игра",
          "robots": "index, follow",
          "og:title": "GTA 5 - Экшн в Лос-Сантосе на PS4 и PS5",
          "og:description": "Исследуйте Лос-Сантос, играйте за трех уникальных персонажей и погрузитесь в мир криминала и адреналина в GTA 5 на PS4 и PS5.",
          "og:image": "https://example.com/image.jpg"
        }
      }

    ],
    lastBuy: [
      {
        date: '03.08.2024',
        delivery: 'boxberry',
        game: 'ELDEN RING',
        avatar: 'https://sun1-21.userapi.com/s/v1/ig2/OCUFOY8VFgSStwIU6FfuPDLnHcgSQQZ8xjJSI5yk7SP-RHhyTRqN4JlBX2_HKFzB1SP9lNtH0836-KAYhhAHIp6a.jpg?quality=95&crop=20,195,1891,1891&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280,1440x1440&ava=1&u=qF-yWCMCITNNmY0tm-rYr5c71vynxhQBOGqNcf-VAMo&cs=200x200',
        description: 'Первый раз заказывал в GamesStoreFun, и остался полностью доволен! Все было организовано на высшем уровне, начиная от оформления заказа и заканчивая быстрой доставкой. Отличный сервис!'
      },
      {
        date: '07.08.2024',
        delivery: 'СДЭК',
        game: 'PACIFIC DRIVE',
        avatar: 'https://sun1-54.userapi.com/s/v1/if1/G6e6hns5XoAZPodKkgyS9cuHFfdzvZebW1bAXTr_-VBJWLsWOePw8ivkD_RgKFkix6tn3-Ek.jpg?quality=96&crop=0,159,960,960&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720&ava=1&u=iq57rD5QCqs_sgp_8pJjTSD7ulTdNu9OfEVr7V-xqX8&cs=200x200',
        description: 'Очень понравилось, как работает команда GamesStoreFun. Мой заказ пришел быстро, несмотря на то, что живу в отдаленном регионе. Игра отличная, рекомендую всем этот магазин!'
      },
      {
        date: '12.08.2024',
        delivery: 'boxberry',
        game: 'Dragons Dogma 2',
        avatar: 'https://sun1-95.userapi.com/s/v1/ig2/HRMzDVdltcFrBOMuyW6iZxS80b_RYlLqmR7l8--aWcc5xtrThsNxqgTYiRL0RTjE1VkewstL5W6Mvo2Af8Bp1us7.jpg?quality=95&crop=586,19,1907,1907&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280,1440x1440&ava=1&u=6pTdYVMEsV0d0rgkF8SX08ORo0yD2mPN_ByS0lWfkyU&cs=200x200',
        description: 'Осталась очень довольна покупкой в GamesStoreFun! Всё прошло гладко, менеджеры очень вежливые и оперативные. Доставка была быстрой, и никаких проблем не возникло.'
      },
      {
        date: '15.08.2024',
        delivery: 'СДЭК',
        game: 'Lies of P',
        avatar: 'https://sun1-22.userapi.com/s/v1/if1/FRSKckXsGu-eqm5QZPn-r4AJzXxs3nNuXY2-FdUYBBEYJt6hLK-kwlCkyrppBwOunnHTdwCq.jpg?quality=96&crop=300,0,1920,1920&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280,1440x1440&ava=1&u=jd7ACsZ_J3tdQ-V7PIQx8zkIyegfNjkdiIVE6ax1fHE&cs=200x200',
        description: 'Отличный магазин! Игры в отличном состоянии, а доставка прямо до двери. Быстрое оформление и качественный сервис – это всё, что нужно. Буду заказывать еще!'
      },
      {
        date: '18.08.2024',
        delivery: 'boxberry',
        game: 'Spider Man 2',
        avatar: 'https://sun1-92.userapi.com/s/v1/ig2/KzQ-qu8ZX00ltU9KmGLWmg6hpM6PajPa7tzBA_D5nTlmMjlh1j8rA6qMYDnhSCc9raySV5RNJnAEgUM03CnMa934.jpg?quality=95&crop=0,200,1613,1613&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280,1440x1440&ava=1&u=aKprKTFMPkv3ub9CeD-rEinfLCb5YkQm22c_whOJzBk&cs=200x200',
        description: 'Все супер! Игра пришла вовремя, упаковка на высоте, никаких повреждений. Магазин в очередной раз оправдал ожидания, теперь только тут буду покупать!'
      },
      {
        date: '20.08.2024',
        delivery: 'СДЭК',
        game: 'DEATHLOOP',
        avatar: 'https://sun1-98.userapi.com/s/v1/if1/qNJz_0EPSKS39uIfUzhr3aoFr7Z-eg3YNxsSwalsHbdcWhu_A4QtOnoX0VmYvFoQJxXTlRSw.jpg?quality=96&crop=37,37,316,316&as=32x32,48x48,72x72,108x108,160x160,240x240&ava=1&u=YmyrcNsHogmW9U0rDkiKWyb7CVbomS9OcQkWqMdr0yc&cs=200x200',
        description: 'Приятно удивлен работой GamesStoreFun! Оперативная доставка, удобный сайт, а главное – честные цены. Спасибо за хорошую игру и отличное обслуживание!'
      },
      {
        date: '23.08.2024',
        delivery: 'boxberry',
        game: 'Mortal Kombat 1',
        avatar: 'https://sun1-99.userapi.com/s/v1/ig2/XW66TQy3n3iXVYyQg9k1uicdGOgVay26osjJSIrfxf9vOgIfH8TMA0xB6wFH0OrI03m4i9IIrL5WDPCDjak5XD0C.jpg?quality=95&crop=3,8,1072,1072&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720&ava=1&u=-2_OiWLuukRDkgbRVslJ8t8ao6nuqYrTPRvIofEngWM&cs=200x200',
        description: 'GamesStoreFun приятно удивил уровнем сервиса! Сотрудники всегда на связи, доставка точно в срок, игра в идеальном состоянии. Рекомендую!'
      },
      {
        date: '25.08.2024',
        delivery: 'СДЭК',
        game: 'Atomic Heart',
        avatar: 'https://sun1-98.userapi.com/s/v1/ig2/THxl8vS_7GcZVQg1mjZ4rQrHJpze5LaPRMfhcAU0dqUIBSmzGTLD2w8Mue9rQ59jqtXzg4mP7sphDlwpAu-c8KTP.jpg?quality=96&crop=0,196,1200,1200&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080&ava=1&u=QXHmrxpnLhUFnwYgMdP0xb56k3oPUeyBo58XDJkn8Vw&cs=200x200',
        description: 'Отличный опыт покупки! Всё прошло без заминок, игра пришла вовремя и полностью оправдала мои ожидания. Большое спасибо команде GamesStoreFun!'
      },
      {
        date: '28.08.2024',
        delivery: 'boxberry',
        game: 'Sekiro',
        avatar: 'https://sun1-27.userapi.com/s/v1/if2/dyNxWvykquEQPCvabryJi-g9YR48A0nxPFRedQyzob_3LMjeeT8x4eTDiZ1Z4au7KKSUXHtJMoKQdWl9sUoPPsJp.jpg?quality=96&crop=61,61,331,331&as=32x32,48x48,72x72,108x108,160x160,240x240&ava=1&u=8LDGBgauBriQAHGbEbTkBbNOTiPJCpZ8STqp4xyMUEc&cs=200x200',
        description: 'Быстрая доставка, хорошее обслуживание и отличная игра – что еще нужно? Приятно было иметь дело с GamesStoreFun. Сервис на уровне!'
      },
      {
        date: '30.08.2024',
        delivery: 'СДЭК',
        game: 'STRAY',
        avatar: 'https://sun1-96.userapi.com/s/v1/ig2/xSxonqSWDLUA6X7LmsUmgWsblv4EgteRLvqVJD_kxF-HE1E2KiHdl_6PcQAsz623Qwhh6R8_aTuhIynVF_X3xiHk.jpg?quality=95&crop=230,762,1279,1279&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080&ava=1&u=6_PUZdSa3q65D2DT6hULzpz4rt99eGd0NJ316IHPXrA&cs=200x200',
        description: 'Купил игру в GamesStoreFun, и был приятно удивлен качеством обслуживания. Быстрая доставка, вежливый менеджер, игра пришла без задержек и в отличном состоянии.'
      }
    ],


    catalogTrash: [],
    searchCatalog: [],
    filterCatalogList: [],
    filter: {
      platform: null,
      sale: null,
      new_arrive: null
    },
    isFilterActive: true
  }),









  actions: {
    // Метод для загрузки данных из localStorage
    loadCatalogTrash() {
      const storedTrash = localStorage.getItem('catalog_trash')
      if (storedTrash) {
        this.catalogTrash = JSON.parse(storedTrash)
        // Обновляем catalog на основе catalogTrash
        this.updateCatalogFromTrash()
      }
    },
    // Метод для обновления catalog из catalogTrash
    updateCatalogFromTrash() {
      this.catalog = this.catalog.map((item: any) => {
        const trashItem = this.catalogTrash.find((trash: any) => trash.id === item.id)
        if (trashItem) {
          // Обновляем item из trashItem
          return {...item, params: {...item.params, inTrash: trashItem.params.inTrash}}
        }
        return item
      })
      this.catalog = this.catalog.map((item: any) => {
        const trashItem = this.catalogTrash.find((trash: any) => trash.id === item.id)
        if (trashItem) {
          // Обновляем item из trashItem
          return {...item, params: {...item.params, inTrash: trashItem.params.inTrash}}
        }
        return item
      })
    },
    inTrash(id: number) {
      // Обновляем catalogTrash из localStorage перед изменениями
      this.loadCatalogTrash()
      if (!this.isFilterActive) {
        this.filterCatalogList = this.filterCatalogList.map((item: any) => {
          if (item.id === id) {
            if (!item?.params?.inTrash) {
              item.params.inTrash = true
            } else {
              item.params.inTrash = false
            }
            if (item.params.inTrash) {
              this.catalogTrash = [...this.catalogTrash, item]
            } else {
              this.catalogTrash = this.catalogTrash.filter((trashItem: any) => trashItem.id !== id)
            }
            localStorage.setItem('catalog_trash', JSON.stringify(this.catalogTrash))
          }
          return item
        })
      } else {
        this.catalog = this.catalog.map((item: any) => {
          if (item.id === id) {
            item.params.inTrash = !item.params.inTrash
            if (item.params.inTrash) {
              this.catalogTrash = [...this.catalogTrash, item]
            } else {
              this.catalogTrash = this.catalogTrash.filter((trashItem: any) => trashItem.id !== id)
            }
            localStorage.setItem('catalog_trash', JSON.stringify(this.catalogTrash))
          }
          return item
        })
      }
    },
    searchTextInCatalog(searchString: string) {
      if (searchString === '') {
        this.searchCatalog = []
      } else {
        const filteredCatalog = this.catalog.filter((item: any) =>
            item.info.name.toLowerCase().includes(searchString.toLowerCase())
        );
        this.searchCatalog = filteredCatalog
      }
    },
    filterCatalog(filters: any) {
      // Создаем пустой массив для сбора всех результатов
      let filteredResults = [...this.catalog];

      // Проходим по каждому фильтру
      for (const key in filters) {
        if (filters.hasOwnProperty(key) && filters[key] !== null) {
          const currentFilterValue = filters[key];
          // Фильтруем каталог по текущему фильтру
          filteredResults = filteredResults.filter((item: any) => item.params[key] === currentFilterValue || currentFilterValue === 1 ? item.params[key] : item.params[key] === currentFilterValue );
        }
      }
      // Обновляем filterCatalogList с уникальными результатами
      this.changeFilterActive(false)
      this.filterCatalogList = filteredResults;
    },

    clearFilterCatalog ()  {
      // ОБНОВЛЯЕМ ИЗБРАННОЕ В КАТАЛОГ ПРИ ЧИСТКЕ
      this.catalog.forEach((catalogItem:any) => {
        this.filterCatalogList.forEach((item:any) => {
          if (catalogItem.id === item.id) {
            catalogItem.params.inTrash = item.params.inTrash
          }
        })
      })
      this.filterCatalogList = []
      for (const key in this.filter) {
        this.filter[key] = null
      }
      this.changeFilterActive(true)
    },

    changeFilterActive (status:boolean)  {
     this.isFilterActive = status
    }
  },
  getters: {
    items: (state: any) => {
      const catalog = state.catalog || [];

      // Функция для проверки, является ли игра со скидкой
      const isOnSale = (item: any) => item.params.sale > 0;

      // Функция для проверки, является ли игра новой
      const isNewArrival = (item: any) => item.params.new_arrive;

      // Функция для сортировки
      const sortCatalog = (items: any[]) => {
        return items.slice().sort((a, b) => {
          if (isOnSale(b) && !isOnSale(a)) return 1;
          if (isOnSale(a) && !isOnSale(b)) return -1;
          if (isNewArrival(b) && !isNewArrival(a)) return 1;
          if (isNewArrival(a) && !isNewArrival(b)) return -1;
          return 0;
        });
      };

      return sortCatalog(catalog);
    },
    itemsTrash: (state:any) => state.catalogTrash || [],
    itemsFilterCatalog: (state:any) => {
      const catalog = state.filterCatalogList || [];

      // Функция для проверки, является ли игра со скидкой
      const isOnSale = (item: any) => item.params.sale > 0;

      // Функция для проверки, является ли игра новой
      const isNewArrival = (item: any) => item.params.new_arrive;

      // Функция для сортировки
      const sortCatalog = (items: any[]) => {
        return items.slice().sort((a, b) => {
          if (isOnSale(b) && !isOnSale(a)) return 1;
          if (isOnSale(a) && !isOnSale(b)) return -1;
          if (isNewArrival(b) && !isNewArrival(a)) return 1;
          if (isNewArrival(a) && !isNewArrival(b)) return -1;
          return 0;
        });
      };

      return sortCatalog(catalog);
    },
    itemsSearchCatalog: (state:any) => state.searchCatalog || [],
    itemsFilter: (state:any) => state.filter || [],
    lastBuyItems: (state:any) => state.lastBuy || [],
    isFilterStatus: (state:any) => state.isFilterActive || false
  }
})
