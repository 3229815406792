const API_URL = process.env.NODE_ENV === 'production' ? 'https://ipinfo.io/213.109.66.163?token=0acee3054541df' : 'https://ipinfo.io/json'

export async function getGeoLocation () {
  try {
    const response = await fetch(API_URL)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()

    if (data && data.country) {
      localStorage.setItem('country', data.country.toLowerCase())
    }

    return data
  } catch (error) {
    console.error('Error fetching geolocation data:', error)
    return null
  }
}
