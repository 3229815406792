<template>
  <section class="banner-main">
    <div class="container">
      <swiper
          :navigation="false"
          :modules="modules"
          :effect="'fade'"
          :autoplay="{ delay: 2500 }"
      >
        <swiper-slide
            v-for="(item, index) in items"
            :key="index"
            v-show="item.banner.image !== null"
        >
          <div
              @click="transitionToCard(item)"
              class="banner-main-card"
              :class="`banner-main-card-${index}`"
          >
            <div class="banner-main-card-image" :style="`background-image: url('${item.banner.image}')`" />
            <div class="container">
              <div class="banner-main-card-info">
                <p class="banner-main-card-info-name">{{ item.params.platform.toUpperCase() }} | {{ item.info.name }}</p>
                <div class="banner-main-card-info-price-wrapper">
                  <p v-if="item.params && item.params.sale > 0" class="banner-main-card-info-price-percent">- {{ item.params.sale }}%</p>
                  <div class="banner-main-card-info-price-info-wrapper">
                    <p v-if="item.info && item.info.price" :style="item.params && item.params.sale > 0 ? 'text-decoration: line-through;' : ''" class="banner-main-card-info-price">
                      {{ item.info.price[switcher.country].toLocaleString() }} {{ item.params && item.params.sale === 0 ? switcher.country === 'kg' ? 'сом' : 'руб' : '' }}
                    </p>
                    <p v-if="item.params && item.params.sale > 0" class="banner-main-card-info-price-sale">{{ calculateDiscountedPrice(item.info.price.kg, item.params.sale).toLocaleString() }} {{ switcher.country === 'kg' ? 'сом' : 'руб' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

import { useSwitcherStore } from '@/store/switcher'
import { useCatalogStore } from "@/store/catalog";
import { useRouter } from 'vue-router'
import { reactive, ref } from 'vue'
import { calculateDiscountedPrice } from "@/utils/helpers";
const catalog = useCatalogStore()
const switcher = useSwitcherStore()
const router = useRouter()
const items = ref(catalog.items.filter((item:any) => item.banner !== null))

const transitionToCard = (item:any) => {
  const type = item?.params?.type === 'игра' ? 'games' : 'product'
  router.push(`/catalog/${item?.params?.platform}/${type}/${item?.id}`)
}

const modules = reactive([Autoplay, EffectFade])
</script>

<style lang="scss">

  .banner-main {
    height: 300px;
    margin-bottom: 40px;
    padding: 10px 0 0 0;
    & > div > div > .swiper-slide { height: 300px; }
  }
  .banner-main-card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in;
    height: 300px;
    border-radius: 5px;
  }
  .banner-main-card-info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
  }
  .banner-main-card-info-price-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .banner-main-card-info-price-percent {
    padding: 5px 7px;
    border: 1px solid #e23881;
    border-radius: 5px;
    background: #1d1f22;
  }
  .banner-main-card-info-price-info-wrapper {
    padding: 5px 7px;
    border: 1px solid #e23881;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #1d1f22;
  }
  .banner-main-card-info-price-sale {
    color: #e23881;
    font-size: 16px;
    font-weight: 500;
  }
  .banner-main-card-info-name, .banner-main-card-info-price, .banner-main-card-info-price-percent {
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
  }
  .banner-main-card-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    .banner-main {
      height: 500px;
      & > div > div > .swiper-slide { height: 500px }
    }
    .banner-main-card {
      height: 500px;
    }
    .banner-main-card-info {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 100%;
    }
    .banner-main-card-info-price-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .banner-main-card-info-price-percent {
      padding: 5px 7px;
      border: 1px solid #e23881;
      border-radius: 5px;
      background: #1d1f22;
    }
    .banner-main-card-info-price-info-wrapper {
      padding: 5px 7px;
      border: 1px solid #e23881;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #1d1f22;
    }
    .banner-main-card-info-price {
      text-decoration: line-through;
    }
    .banner-main-card-info-price-sale {
      color: #e23881;
      font-size: 16px;
      font-weight: 500;
    }
    .banner-main-card-info-name, .banner-main-card-info-price, .banner-main-card-info-price-percent {
      color: #FFF;
      font-weight: 500;
      font-size: 16px;
    }
    .banner-main-card-image {
      background-position: inherit;
      height: 100%;
    }
  }

  @media screen and (min-width: 1024px) {
    .banner-main {
      height: 585px;
      & > div > div > .swiper-slide { height: 585px }
    }
    .banner-main-card {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s ease-in;
      height: 585px;
    }
    .banner-main-card-info {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
    }
    .banner-main-card-info-price-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .banner-main-card-info-price-percent {
      padding: 5px 7px;
      border: 1px solid #e23881;
      border-radius: 5px;
      background: #1d1f22;
    }
    .banner-main-card-info-price-info-wrapper {
      padding: 5px 7px;
      border: 1px solid #e23881;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #1d1f22;
    }
    .banner-main-card-info-price {
      text-decoration: line-through;
    }
    .banner-main-card-info-price-sale {
      color: #e23881;
      font-size: 14px;
      font-weight: 500;
    }
    .banner-main-card-info-name, .banner-main-card-info-price, .banner-main-card-info-price-percent {
      color: #FFF;
      font-weight: 500;
      font-size: 14px;
    }
  }

  @media screen and (min-width: 1440px) {
    .banner-main {
      height: 585px;
      & > div > div > .swiper-slide { height: 585px }
    }
    .banner-main-card {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s ease-in;
    }
    .banner-main-card-info {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
    }
    .banner-main-card-info-price-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .banner-main-card-info-price-percent {
      padding: 5px 7px;
      border: 1px solid #e23881;
      border-radius: 5px;
      background: #1d1f22;
    }
    .banner-main-card-info-price-info-wrapper {
      padding: 5px 7px;
      border: 1px solid #e23881;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #1d1f22;
    }
    .banner-main-card-info-price {
      text-decoration: line-through;
    }
    .banner-main-card-info-price-sale {
      color: #e23881;
      font-size: 24px;
      font-weight: 500;
    }
    .banner-main-card-info-name, .banner-main-card-info-price, .banner-main-card-info-price-percent {
      color: #FFF;
      font-weight: 500;
      font-size: 22px;
    }
  }

  @media (hover: hover) {
    .banner-main-card:hover {
      & > .banner-main-card-image {
        transition: all 0.3s ease-in;
        transform: scale(1.05);
      }
    }
  }
</style>
