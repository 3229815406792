<template>
  <router-link :to="transitionToPage" class="main-card">
    <div class="main-card-top">
      <p v-if="item.params && item.params.sale > 0" class="main-card-sale">
        -{{ item.params.sale }}%</p>
      <picture class="main-card-picture">
        <img v-if="item.info && (item.info.image && item.info.name)" class="main-card-image" :src="item.info.image" :alt="item.info.name">
      </picture>
    </div>
    <p v-if="item.info && item.info.name" class="main-card-name">{{ item.params.platform.toUpperCase() }} | {{ item.info.name }}</p>
    <div v-if="visiblePrice" class="main-card-price-wrapper">
      <p v-if="item.info && item.info.price" :style="item.params && item.params.sale > 0 ? 'text-decoration: line-through' : ''" class="main-card-price">{{ item.info.price[switcher.country].toLocaleString() }} {{ item.params && item.params.sale === 0 ? switcher.country === 'kg' ? 'сом' : 'руб' : '' }}</p>
      <p v-if="item.params && item.params.sale > 0" class="main-card-price main-card-price-sale">{{ amountDiscount.toLocaleString() }} {{ switcher.country === 'kg' ? 'сом' : 'руб' }}</p>
    </div>
    <button
      v-if="visibleTrashBtn"
      @click.stop="addToCart"
            class="main-card-trash"
            :class="item.params.inTrash ? 'main-card-trash-active' : ''"
    >{{ item.params.inTrash ? 'Удалить с корзины' : 'В корзину'}}</button>
    <p v-if="item.params" class="main-card-status"> {{ item.params.stock > 0 ? 'В наличии' : 'Под заказ'}}</p>
  </router-link>
</template>

<script setup lang="ts">
import {computed, defineProps, nextTick} from 'vue'
import { useCatalogStore } from '@/store/catalog'
import { calculateDiscountedPrice } from '@/utils/helpers'
import { useSwitcherStore } from '@/store/switcher'
import { tgBotAction } from "@/api/telegram";

const store = useCatalogStore()
const switcher = useSwitcherStore()

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  visibleTrashBtn: {
    type: Boolean,
    default: true
  },
  visiblePrice: {
    type: Boolean,
    default: true
  }
})

const amountDiscount =  computed(() => {
  return calculateDiscountedPrice(props?.item.info.price[switcher.country], props?.item.params.sale)
})

const transitionToPage = computed(() => {
  const type = props?.item?.params?.type === 'игра' ? 'games' : 'product'
  return `/catalog/${props?.item?.params?.platform}/${type}/${props?.item?.id}`
})

  const addToCart = (event:any) => {
  event.preventDefault()
  store.inTrash(props?.item?.id)
  nextTick(() => {
    tgBotAction(`${props?.item?.params?.inTrash ? 'Добавили игру в корзину' : 'Удалили игру из корзины'}`,
        { platform: props?.item?.params?.platform, name: props?.item?.info.name, price: props?.item.info.price[switcher.country], country: switcher.country })
  })
}

</script>

<style lang="scss" scoped>
  .main-card {
    cursor: pointer;
    padding: 10px 0;
    transition: all 0.1s ease-in;
    color: #242424;
    position: relative;
    display: block;
  }
  .main-card-top {
    display: flex;
    justify-content: center;
  }
  .main-card-picture, .main-card-image {
    transition: all 0.2s ease-in;
  }
  .main-card-name {
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    min-height: 41.38px;
  }
  .main-card-price {
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
  }
  .main-card-trash {
    background-color: #1d61e8;
    border: 1px solid white;
    width: 100%;
    border-radius: 8px;
    padding: 10px 0;
    font-weight: 500;
    font-size: 14px;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    position: relative;
    z-index: 98;
  }
  .main-card-trash-active {
    background-color: transparent;
    border: 1px solid #1d61e8;
    color: #1d61e8;
  }
  .main-card-status {
    font-weight: 400;
    font-size: 14px;
    color: #c5c5c7;
  }
  .main-card-sale {
    width: 90px;
    height: 25px;
    border-radius: 7px;
    background-color: #e23881;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    font-size: 14px;
    z-index: 98;
  }
  .main-card-price-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .main-card-price-sale {
    color: #e23881;
  }
  .main-card-image {
    width: 100%;
    height: 256px;
  }
  @media screen and (min-width: 576px){
    .main-card {
      padding: 10px 20px;
    }
    .main-card-trash {
      font-size: 16px;
    }
    .main-card-price {
      font-size: 20px;
    }
    .main-card-image {
      width: 186px;
      height: 256px;
    }
  }
  @media screen and (min-width: 768px){
    .main-card-image {
      width: 186px;
      height: 256px;
    }
  }
  @media (hover: hover) {
    .main-card:hover {
      transition: all 0.1s ease-in;
      outline: 1px solid #e23881;
      border-radius: 8px;
      -webkit-box-shadow: 0 6px 8px 0 rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0 6px 8px 0 rgba(34, 60, 80, 0.2);
      box-shadow: 0 6px 8px 0 rgba(34, 60, 80, 0.2);
      & > .main-card-picture > .main-card-image {
        transition: all 0.2s ease-in;
        transform: scale(1.05);
      }
      & > .main-card-name {
        transition: all 0.2s ease-in;
        color: #e23881;
      }
    }
  }

</style>
