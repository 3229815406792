<template>
  <section class="delivery">
    <div class="container">
      <InfoDiePage title="Доставка и оплата" />
      <LayoutHelper>
        <template #content>
          <div class="delivery-bishkek">
            <p class="delivery-title">Доставка по городу Бишкек</p>
            <p class="delivery-subtitle">Мы ценим ваше время. Поэтому предлагаем бесплатную доставку игр по всему городу Бишкек - <b>бесплатно</b></p>
          </div>
          <div class="delivery-kirgistan">
            <p class="delivery-title">Доставка по Кыргызстану</p>
            <p class="delivery-subtitle">
              Платная доставка по Кыргызстану осуществляется через курьерскую службу Ылдам Экспресс. Плата за доставку может варьироваться в зависимости от ряда факторов, включая вес посылки, удаленность места доставки, выбранную курьерскую службу и дополнительные услуги, такие как страхование посылки или экспресс-доставка.
              <br><br>
              Стоимость доставки может быть рассчитана на основе тарифов курьерской службы и других факторов, учитывающих особенности заказа и требования клиента.
              <br><br>
              При платной доставке по Кыргызстану важно учитывать возможные условия доставки, сроки и услуги, предоставляемые курьерской службой, чтобы обеспечить комфортный и своевременный получение заказа клиентом.
              <br><br>
              <span>Срок доставки 1-2 дня</span>
            </p>
          </div>
          <div class="delivery-kirgistan">
            <p class="delivery-title">Доставка по России</p>
            <p class="delivery-subtitle">
              Доставка в Россию осуществляется через службу доставки СДЕК. Плата за доставку может варьироваться в зависимости от ряда факторов, включая вес посылки, удаленность места доставки и дополнительные услуги, такие как страхование посылки или экспресс-доставка.
              <br><br>
              Стоимость доставки может быть рассчитана на основе тарифов курьерской службы и других факторов, учитывающих особенности заказа и требования клиента.
              <br><br>
              Важно учитывать возможные условия доставки, сроки и услуги, предоставляемые курьерской службой, чтобы обеспечить комфортный и своевременный получение заказа клиентом.
              <br><br>
              Срок доставки 3-15 дней
            </p>
          </div>
          <div class="delivery-pay">
            <p class="delivery-title delivery-pat-title">Оплата</p>
            <p class="delivery-subtitle">
              Оплата наличными при получении товара доступна по городу Бишкек
            </p>
            <br>
            <p class="delivery-subtitle">
              Доступна для оплата по всему Кыргызстану и России
            </p>
            <br>
            <p class="delivery-subtitle">
              Мы принимаем оплату переводом на Бакай Банк, Компаньон, Тинькоф Банк
            </p>
          </div>
        </template>
      </LayoutHelper>
    </div>
  </section>
</template>

<script lang="ts" setup>
import LayoutHelper from '@/components/universal/LayoutHelper/indexC.vue'
import InfoDiePage from '@/components/universal/InfoDieSelectPage/index.vue'
import { onMounted } from "vue";
import { tgBotAction } from "@/api/telegram";
onMounted(() => {
  tgBotAction( 'Перешли на страницу доставка')
})


</script>

<style lang="scss" scoped>
  .delivery, .delivery-kirgistan {
    margin: 20px 0;
  }
  .delivery-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
  .delivery-subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #242424;
  }
  .delivery-bishkek {
    margin-bottom: 10px;
  }
  .delivery-pat-title {
    margin-bottom: 12px;
  }

  @media screen and (min-width: 1024px) {
    .delivery-bishkek {
      margin-bottom: 30px;
    }
    .delivery, .delivery-kirgistan {
      margin: 40px 0;
    }
    .delivery-title {
      font-size: 24px;
      margin: 0 0 25px 0;
    }
  }
</style>
