import { defineStore } from 'pinia'

export const useSwitcherStore = defineStore('switcher', {
  state: ():any => ({
    sidebarTrash: false,
    sidebarMenu: false,
    sidebarFilter: false,
    country: localStorage.getItem('country') || 'kg' // Инициализация значения country
  }),
  actions: {
    changeSideBarTrash () {
      setTimeout(() => {
        const wrapper = document.querySelector('.side-bar-trash-block')
        if(wrapper) wrapper.classList.add('side-bar-trash-block-active')
      }, 50)
      this.sidebarTrash = !this.sidebarTrash
    },
    changeSideBarMenu () {
      setTimeout(() => {
        const wrapper = document.querySelector('.side-bar-menu-block')
        if(wrapper) wrapper.classList.add('side-bar-menu-block-active')
      }, 50)
      this.sidebarMenu = !this.sidebarMenu
    },
    changeSideBarFilter () {
      setTimeout(() => {
        const wrapper = document.querySelector('.side-bar-filter-block')
        if(wrapper) wrapper.classList.add('side-bar-filter-block-active')
      }, 50)
      this.sidebarFilter = !this.sidebarFilter
    }
  },
  getters: {
    sidebarTrashStatus: (state) => state.sidebarTrash || false,
    sidebarMenuStatus: (state) => state.sidebarMenu || false,
    sidebarFilterStatus: (state) => state.sidebarFilter || false
  }
})
