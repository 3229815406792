<template>
 <section class="card-id">
    <div class="container">
      <!--  ВЕРХНЯЯ ИНОФРМАЦИЯ КАРТОЧКИ  -->
      <div class="card-id-top">
        <div class="card-id-top-left">
          <img :src="item.info.image" :alt="item.info && item.info.name" class="card-id-top-left-view">
<!--          <div class="card-id-top-left-tab">-->
<!--            <img class="card-id-top-left-view-picture" :src="../../../src/assets/images/catalog/dragonsdogma2-ps5.webp" alt="dragonsdogma2">-->
<!--            <img class="card-id-top-left-view-video" src="../../../src/assets/images/catalog/dragonsdogma2-ps5.webp" alt="dragonsdogma2">-->
<!--          </div>-->
        </div>
        <div class="card-id-top-right">
          <h2 class="card-id-top-right-name">{{ item.params.platform.toUpperCase() }} | {{ item.info.name }}</h2>
          <div class="card-id-top-right-info">
            <p class="card-id-top-right-info-prop">Тип:  <span class="card-id-top-right-info-prop-span">{{ item.params.type }}</span></p>
            <p class="card-id-top-right-info-prop">Жанр:  <span class="card-id-top-right-info-prop-span">{{ item.params.genre }}</span></p>
            <p class="card-id-top-right-info-prop">Платформа:   <span class="card-id-top-right-info-prop-span">{{ item.params.platform }}</span></p>
            <p class="card-id-top-right-info-prop">Год релиза:   <span class="card-id-top-right-info-prop-span">{{ item.params.year_of_release }}</span></p>
            <p class="card-id-top-right-info-prop">Язык интерфейса:   <span class="card-id-top-right-info-prop-span">{{ item.params.interface_language }}</span></p>
            <p class="card-id-top-right-info-prop">Язык озвучки:   <span class="card-id-top-right-info-prop-span">{{ item.params.voice_language }}</span></p>
            <p class="card-id-top-right-info-prop">Совместимость с VR шлемами:   <span class="card-id-top-right-info-prop-span">{{ item.params.vr_support ? 'Да' : 'Нет' }}</span></p>
            <p class="card-id-top-right-info-prop">Однопользовательский режим:   <span class="card-id-top-right-info-prop-span">{{ item.params.single_mode ? 'Есть' : 'Нет' }}</span></p>
            <p class="card-id-top-right-info-prop">Многопользовательский режим:   <span class="card-id-top-right-info-prop-span">{{ item.params.multiplayer_mode ? 'Есть' : 'Нет' }}</span></p>
            <p class="card-id-top-right-info-prop">Возрастные ограничения:   <span class="card-id-top-right-info-prop-span">{{ item.params.age_restrictions }}+</span></p>
            <p class="card-id-top-right-info-prop">Тип носителя:   <span class="card-id-top-right-info-prop-span">{{ item.params.media_type_electron ? 'ключ' : 'лицензионный диск'}}</span></p>
          </div>
          <div class="card-id-top-right-order">
            <div class="card-id-top-right-price-wrapper">
              <p :style="item.params && item.params.sale > 0 ? 'text-decoration: line-through' : ''"  class="card-id-top-right-price">{{ item.info.price[switcher.country].toLocaleString() }}
                {{ switcher.country === 'kg' ? 'сом' : 'руб' }}
              </p>
              <p v-if="item.params && item.params.sale > 0" class="card-id-top-right-price card-id-top-right-price-sale">{{ calculateDiscountedPrice(item.info.price[switcher.country], item.params?.sale).toLocaleString() }}
                {{ switcher.country === 'kg' ? 'сом' : 'руб' }}
              </p>
            </div>
            <div class="card-id-top-right-buttons">
              <button @click.stop="store.inTrash(item.id)" class="card-id-top-right-trash" :class="item.params.inTrash ? 'card-id-top-right-trash-active' : ''">{{ item.params.inTrash ? 'Удалить с корзины' : 'В корзину'}}</button>
<!--              <button class="card-id-top-right-buy">Купить сейчас</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
   <!-- ОПИСАНИЕ -->
   <div class="card-id-description">
     <div class="container">
       <div class="card-id-description-wrapper">
         <div class="card-id-description-text">
           <h4 class="card-id-description-text-title">Описание</h4>
           <p class="card-id-description-text-html" v-html="item.info.description" />
         </div>
         <div class="card-id-description-info">
          <div class="card-id-description-info-scroll">
            <h4 class="card-id-description-info-scroll-title">Краткая информация</h4>
            <div class="card-id-top-right-info">
              <p class="card-id-top-right-info-prop">Тип:  <span class="card-id-top-right-info-prop-span">{{ item.params.type }}</span></p>
              <p class="card-id-top-right-info-prop">Тип носителя:   <span class="card-id-top-right-info-prop-span">{{ item.params.media_type_electron ? 'ключ' : 'лицензионный диск'}}</span></p>
              <p class="card-id-top-right-info-prop">Жанр:  <span class="card-id-top-right-info-prop-span">{{ item.params.genre }}</span></p>
              <p class="card-id-top-right-info-prop">Платформа:   <span class="card-id-top-right-info-prop-span">{{ item.params.platform }}</span></p>
            </div>
          </div>
         </div>
       </div>
     </div>
   </div>
   <!-- ПОХОЖИЕ ИГРЫ -->
   <div class="container" v-if="Array.isArray(similarList) && similarList.length">
     <div class="similar">
       <h2 class="similar-title">Похожие игры</h2>
       <swiper
           :breakpoints="breakpoints"
           :space-between="10"
           :navigation="false"
           :modules="modules"
           :autoplay="{ delay: 2500 }"
       >
         <swiper-slide
             v-for="item in similarList"
             :key="item.id"
         >
           <CardMain
               :item="item"
               :visible-trash-btn="false"
               :visible-price="false"
           />
         </swiper-slide>
       </swiper>
     </div>
   </div>
 </section>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { calculateDiscountedPrice } from '@/utils/helpers'
import CardMain from '@/components/universal/Cards/MainC.vue'

import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'
import { useCatalogStore } from '@/store/catalog'
import {computed, reactive, watch} from 'vue'
import { useSwitcherStore } from '@/store/switcher'
const route = useRoute()
const { id } = route?.params
const store = useCatalogStore()
const switcher = useSwitcherStore()
let item:any = {}

item = computed(() => store.isFilterActive ? store.items.find((item:any) => item.id === Number(id)) : store.itemsFilterCatalog.find((item:any) => item.id === Number(id)))
const similarList = store.items.filter((similar:any) => similar.params.genre === item.value.params.genre)

const breakpoints = {
  360: {
    slidesPerView: 2,
    spaceBetween: 30
  },
  576: {
    slidesPerView: 2,
    spaceBetween: 15
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 30
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 40
  }
}

useHead({
  title: `Купить ${item.value.seo.title} | GamesStoreFun | Играй с нами`, // Заголовок страницы, отображаемый в результатах поиска и на вкладке браузера

  meta: [
    { name: 'description', content: item?.value?.seo?.description }, // Описание страницы для сниппетов поисковых систем
    { name: 'keywords', content: item?.value?.seo?.keywords }, // Ключевые слова (хотя сейчас их влияние на SEO меньше, чем раньше)

    // Open Graph метатеги
    { property: 'og:title', content: item?.value?.seo?.og?.title }, // Заголовок для социальных сетей
    { property: 'og:description', content: item?.value?.seo?.og?.description }, // Описание для социальных сетей
    { property: 'og:image', content: item?.value?.seo?.og?.image }, // Изображение для социальных сетей

    // Дополнительные метатеги
    { name: 'robots', content: 'index, follow' }, // Инструкции для поисковых систем
  ],
  link: [
    {
      rel: 'canonical',
      href: `https://gamesstore.fun/catalog/${item?.value?.params?.platform}/${item?.value?.params?.type === 'игра' ? 'games' : 'device'}/${item?.value?.id}` // Закрыта строка шаблона
    }
  ]
})

watch(() => route.params.id, (newId) => {
  item = computed(() => store.isFilterActive ? store.items.find((item:any) => item.id === Number(newId)) :  store.itemsFilterCatalog.find((item:any) => item.id === Number(newId)))
})

const modules = reactive([Autoplay])
</script>

<style lang="scss">
  .card-id {
    margin: 40px 0 30px 0;
  }
  .card-id-top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 25px;
  }
  .card-id-top-left {
    display: flex;
    justify-content: center;
  }
  .card-id-top-left-view {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: sticky;
    top: 10px;
  }
  .card-id-top-left-tab {
    width: 600px;
    margin-top: 30px;
  }
  .card-id-top-left-view-picture, .card-id-top-left-view-video {
    width: 100px;
    height: 100px;
  }
  .card-id-top-right-name {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 28px;
    color: #242424;
  }
  .card-id-top-right-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .card-id-top-right-info-prop {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    color: #818c9d;
    border-bottom: 1px solid #989ca3;
    padding-bottom: 20px;
  }
  .card-id-top-right-info-prop:last-child {
    border: none;
    padding-bottom: 0;
  }
  .card-id-top-right-order {
    margin-top: 35px;
  }
  .card-id-top-right-buttons {
    margin-top: 30px;
    display: flex;
    gap: 20px;
  }
  .card-id-top-right-trash, .card-id-top-right-buy {
    border-radius: 8px;
    background-color: #1d61e8;
    color: white;
    font-size: 16px;
    padding: 10px 0;
    width: 100%;
  }
  .card-id-description {
    margin: 20px 0 0 0;
    background-color: #f6f6f6;
    padding: 20px 0;
  }
  .card-id-description-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .card-id-description-text-title {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 30px 0;
    color: #242424;
  }
  .card-id-description-text-html {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #818c9d;
  }
  .card-id-description-info-scroll-title {
    font-size: 22px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 30px;
  }
  .card-id-description-text, .card-id-description-info-scroll {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
  }
  .card-id-description-info {
    height: 100%;
  }
  .card-id-description-info-scroll {
    display: none;
    position: sticky;
    top: 30px;
  }
  .card-id-top-right-trash-active {
    background-color: white!important;
    border: 1px solid #1d61e8;
    color: #1d61e8!important;
  }
  .card-id-top-right-price {
    font-weight: 600;
    font-size: 24px;
    color: #242424;
  }
  .card-id-top-right-price-sale {
    color: #e23881;
  }
  .card-id-top-right-price-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .similar {
    margin: 30px 0 0 0;
  }
  .similar-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #242424;
  }
  @media screen and (min-width: 768px){
    .card-id-top {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
    .card-id-description-info-scroll {
      display: block;
    }
    .card-id-description-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
    .card-id-description {
      margin: 30px 0 0 0;
      background-color: #f6f6f6;
      padding: 40px 0;
    }
    .card-id-top-right-name {
      margin-bottom: 30px;
    }
    .card-id-top-left-view {
      width: 450px;
      max-width: 100%;
      height: 550px;
    }
    .card-id-top {
      gap: 30px;
    }
    .card-id-top-right-order {
      margin-top: 50px;
    }
    .card-id-top-right-trash, .card-id-top-right-buy {
      border-radius: 8px;
      background-color: #1d61e8;
      color: white;
      font-size: 16px;
      padding: 10px 50px;
      width: auto;
    }
    .similar-title {
      font-size: 28px;
    }
    .similar {
      margin: 50px 0 0 0;
      & > .swiper > .swiper-wrapper { padding: 10px 0 10px 1px }
    }
  }
</style>
