<template>
 <a href="/" class="company-logo">
   <img class="company-logo-icon" src="../../../public/img/logo.jpg" alt="GamesHubShop">
   <div class="company-logo-text">
     <p class="company-logo-text-name">GamesStore <span class="company-logo-text-name-span">Fun</span></p>
     <p class="company-logo-text-offer">Будь в игре!</p>
   </div>
 </a>
</template>

<script setup lang="ts">
</script>

<style scoped>
.company-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.company-logo-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.company-logo-text-name {
  font-weight: bold;
  font-size: 14px;
}
.company-logo-text-offer {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
}
.company-logo-text-name-span {
  color: #e23881;
}
.company-logo-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .company-logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .company-logo-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .company-logo-text-name {
    font-weight: bold;
    font-size: 14px;
  }
  .company-logo-text-offer {
    font-weight: 500;
    font-size: 12px;
    text-align: right;
  }
  .company-logo-text-name-span {
    color: #e23881;
  }
  .company-logo-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .company-logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .company-logo-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .company-logo-text-name {
    font-weight: bold;
    font-size: 16px;
  }
  .company-logo-text-offer {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
  }
  .company-logo-text-name-span {
    color: #e23881;
  }
  .company-logo-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

</style>
