<template>
  <section class="side-bar-menu" @click.self="switcher.changeSideBarMenu">
    <div class="side-bar-menu-block">
      <h4 class="side-bar-menu-block-title">Меню</h4>
      <button class="side-bar-menu-close" @click.stop="switcher.changeSideBarMenu">
        <svg data-v-12de1000="" class="side-bar-menu-close-icon" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
          <path data-v-12de1000="" d="M9.63599 22.364L22.3639 9.63603" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path>
          <path data-v-12de1000="" d="M9.63599 9.63602L22.3639 22.3639" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path></svg>
      </button>
      <div class="side-bar-menu-block-menu">
        <button @click.stop="scrollToCatalog('catalog-title')" class="side-bar-menu-block-menu-item">Каталог</button>
        <router-link to="/check-availability/" @click.stop="switcher.changeSideBarMenu" class="side-bar-menu-block-menu-item">Уточнить наличие</router-link>
        <router-link to="/check-availability/" @click.stop="scrollToCatalog('last-buy')" class="side-bar-menu-block-menu-item">Отзывы</router-link>
        <router-link to="/delivery/" @click.stop="switcher.changeSideBarMenu" class="side-bar-menu-block-menu-item">Доставка и оплата</router-link>
        <router-link to="/contacts/" @click.stop="switcher.changeSideBarMenu" class="side-bar-menu-block-menu-item">Контакты</router-link>
        <button  @click.stop="scrollToCatalog('accardeon-title')" class="side-bar-menu-block-menu-item">Игровой FAQ</button>
      </div>
      <div class="side-bar-menu-logo">
        <LogoCompany />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import LogoCompany from '@/components/universal/LogoC.vue'
const switcher = useSwitcherStore()
import { nextTick } from 'vue'
import { useSwitcherStore } from '@/store/switcher.ts'
import { useRouter } from "vue-router";
import {scrollToElement} from "@/utils/helpers";
const router = useRouter()

const scrollToCatalog = (className: string) => {
  router.push('/').then(() => {
    setTimeout(() => {
      scrollToElement({ type: 'class', element: className, offset: 100 })
      switcher.changeSideBarMenu()
    }, 50)
  })
}
</script>

<style lang="scss" scoped>
  .side-bar-menu {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: flex-start;
    z-index: 99;
  }
  .side-bar-menu-block {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    position: relative;
    padding: 0 0 20px 0;
    left: -200px; /* Скрыто изначально за левым краем */
    transition: left 0.3s ease; /* Плавный переход */
  }
  .side-bar-menu-block-active {
    left: 0;
    transition: left 0.3s ease; /* Плавный переход */
  }
  .side-bar-menu-logo {
    padding: 0 0 0 20px;
  }
  .side-bar-menu-close {
    position: absolute;
    top: 0;
    right: 0;
    background: #e23881;
    width: 65px;
    height: 43px;
    border-radius: 0 0 0 10px;
  }
  .side-bar-menu-close-icon {
    width: 30px;
    height: 30px;
  }
  .side-bar-menu-block-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .side-bar-menu-block-menu-item {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0,0,0,0.105);
    width: 100%;
    font-size: 17px;
    font-weight: 500;
  }
  .side-bar-menu-block-menu-item:first-child {
    border-top: 1px solid rgba(0,0,0,0.105);
  }
  .side-bar-menu-block-title {
    font-weight: 500;
    font-size: 20px;
    color: #242424;
    padding: 10px;
    border-bottom: 1px solid #e7dddd;
  }
</style>
