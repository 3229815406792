<template>
  <header>
    <Header />
  </header>
  <div class="search-input-wrapper ">
    <div class="container">
      <SearchInput />
    </div>
  </div>
  <router-view/>
  <footer>
    <Footer />
  </footer>
  <SideBarMenu v-if="switcher.sidebarMenuStatus" />
  <SideBarTrash v-if="switcher.sidebarTrashStatus"/>
  <SideBarMenuBottom />
</template>

<script setup lang="ts">
import Header from '@/components/universal/HeaderC.vue'
import Footer from '@/components/universal/FooterC.vue'
import SideBarTrash from '@/components/universal/SideBarTrash/indexC.vue'
import SideBarMenuBottom from '@/components/universal/SideBarMenuBottom/index.vue'
import SideBarMenu from '@/components/universal/SideBarMenu/index.vue'
import SearchInput from '@/components/universal/SearchInput/index.vue'
import { useSwitcherStore } from '@/store/switcher'
import { useCatalogStore } from '@/store/catalog'
import { getGeoLocation } from '@/api/services/GeoLocation'
import { onMounted } from 'vue'
const switcher = useSwitcherStore()
const catalog = useCatalogStore()

onMounted(() => {
  if (!localStorage.getItem('country')) {
    getGeoLocation()
  }
  catalog.loadCatalogTrash()
})

</script>
<style lang="scss" scoped>
.search-input-wrapper {
  padding: 7px;
  background-color: #1d61e8;
  position: sticky;
  top: 0;
  z-index: 99;
}
@media screen and (min-width: 576px) {
  .search-input-wrapper {
    display: none;
  }
}
</style>
