<template>
  <section class="side-bar-filter" @click.self="switcher.changeSideBarFilter">
    <div class="side-bar-filter-block">
      <div>
        <div class="side-bar-filter-top">
          <button @click.stop="switcher.changeSideBarFilter" class="side-bar-filter-top-close">
            <svg class="side-bar-filter-top-close-icon" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
              <path d="M9.63599 22.364L22.3639 9.63603" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round">
              </path>
              <path d="M9.63599 9.63602L22.3639 22.3639" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"></path></svg>
          </button>
          <h4 class="side-bar-filter-top-title">Фильтры</h4>
        </div>
        <div class="side-bar-filter-middle">
          <div class="side-bar-filter-input-wrapper">
            <label for="game-console" class="side-bar-filter-input-wrapper-label">Приставка:</label>
            <select v-model="store.itemsFilter.platform" @change="store.filterCatalog(store.itemsFilter)" id="game-console" class="side-bar-filter-input-wrapper-select">
              <option :value="null" selected>Все</option>
              <option :value="'ps5'">PlayStation 5</option>
              <option :value="'ps4'">PlayStation 4</option>
            </select>
          </div>
          <div class="side-bar-filter-input-wrapper">
            <label for="sale" class="side-bar-filter-input-wrapper-label">Скидка:</label>
            <select v-model="store.itemsFilter.sale" id="sale" @change="store.filterCatalog(store.itemsFilter)" class="side-bar-filter-input-wrapper-select">
              <option :value="null" selected>Все</option>
              <option :value="1">Показать</option>
            </select>
          </div>
          <p class="side-bar-filter-result">Найдено: <span class="side-bar-filter-result-span">{{ store.itemsFilterCatalog.length }}</span></p>
          <button @click.stop="showResultFilter" class="side-bar-filter-button-add">Применить фильтры</button>
          <button @click.stop="store.clearFilterCatalog" class="side-bar-filter-button-clear">Очистить фильтры</button>
        </div>
      </div>
      <button class="side-bar-filter-middle-empty-close"  @click.self="switcher.changeSideBarFilter">Закрыть</button>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { scrollToElement } from '@/utils/helpers'
import { useSwitcherStore } from '@/store/switcher'
import { useCatalogStore } from '@/store/catalog'
const switcher = useSwitcherStore()
const store = useCatalogStore()

const showResultFilter = () => {
  switcher.changeSideBarFilter();
  scrollToElement({ type: 'class', element: 'catalog-wrapper', offset: 100 })
}

</script>

<style lang="scss" scoped>
  .side-bar-filter {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: flex-end;
    z-index: 99;
  }
  .side-bar-filter-block {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    right: -300px; /* Скрыто изначально за левым краем */
    transition: right 0.3s ease; /* Плавный переход */
    width: 300px;
    padding: 0 0 10px 0;
  }
  .side-bar-filter-block-active {
    right: 0;
    transition: right 0.3s ease; /* Плавный переход */
  }
  .side-bar-filter-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    margin-bottom: 10px;
  }
  .side-bar-filter-top-close {
    position: absolute;
    top: 0;
    left: 0;
    background: #e23881;
    width: 65px;
    height: 43px;
    border-radius: 0 0 10px 0;
  }
  .side-bar-filter-top-close-icon {
    width: 30px;
    height: 30px;
  }
  .side-bar-filter-top-title {
    font-weight: 500;
    font-size: 20px;
    color: #242424;
  }
  .side-bar-filter-bottom-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .side-bar-filter-middle {
    padding: 0 10px;
    margin-top: 30px;
  }
  .side-bar-filter-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-bottom: 20px;
  }
  .side-bar-filter-middle-empty-close, .side-bar-filter-button-clear, .side-bar-filter-button-add {
    font-weight: 500;
    font-size: 14px;
    border-radius: 7px;
    text-transform: uppercase;
    padding: 10px 0;
    margin: 0 10px;
    background-color: #ffe0f0;
    color: #ef689a;
  }
  .side-bar-filter-button-clear,  {
    background-color: #e23881;
    color: white;
    margin: 0;
    width: 100%;
  }
  .side-bar-filter-button-add,  {
    background-color: white;
    color: #e23881;
    width: 100%;
    border: 1px solid #e23881;
    margin: 20px 0 10px 0;
  }
  .side-bar-filter-input-wrapper-label, .side-bar-filter-result {
    font-weight: 500;
    font-size: 18px;
  }
  .side-bar-filter-result-span {
    color: #e23881;
  }
  .side-bar-filter-input-wrapper-select {
    font-weight: 500;
    font-size: 16px;
    height: 40px;
    padding: 0 10px;
    border-radius: 10px;
    &:focus {
      outline: none;
    }
  }
</style>
